import { isEmpty } from "lodash";

const decimalRegex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

export function getUserName(firstName: string, lastName: string) {
  let response = "";
  if (!isEmpty(firstName)) {
    response = firstName.substr(0, 1).toUpperCase();
    if (firstName.length > 1) {
      response += firstName.substr(1, firstName.length - 1).toLowerCase();
    }
  }
  if (!isEmpty(lastName)) {
    response += " " + lastName.toUpperCase();
  }
  return response;
}

export function upper(value: string) {
  return isEmpty(value) ? "" : value.toUpperCase();
}

export function getSexValues() {
  const responses = [
    { value: "m", label: "Homme" },
    { value: "f", label: "Femme" },
  ];
  return responses;
}

export function getFolderStateIcon(value: string) {
  let response = null;
  switch (value) {
    case "draft":
      response = "Brouillon";
      break;
    case "canceled":
      response = "Annulé";
      break;
    case "rejected":
      response = "Rejeté";
      break;
    case "pending":
      response = "En cours";
      break;
    case "finished":
      response = "Terminé";
      break;
  }
  return response;
}

export function getList(list = [], value = "", label = "") {
  const responses: any[] = [];
  list.map((p) => {
    responses.push({
      value: p[value],
      label: p[label],
    });
  });
  return responses;
}

export function isNumeric(value: any) {
  return decimalRegex.test(value);
}

export function getUid(value = "") {
  let response = null;
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
}

export const phoneRegex = /^[+][(]{0,1}[0-9]{1,4}[)]{0,1}([-\s\./0-9]{6,10})$/; //eslint-disable-line no-useless-escape

export function removeHtml(value: string) {
  if (isEmpty(value)) {
    return "";
  }
  return value.replace(/<[^>]*>?/gm, "");
}
