import { Avatar, Col, Row, Typography } from "antd";
import React from "react";

export const ItemValue: React.FC<{
  title: string;
  value: string;
  icon?: string;
}> = ({ icon, title, value }) => {
  return (
    <Row gutter={4}>
      {icon && (
        <Col>
          <Avatar></Avatar>
        </Col>
      )}
      <Col>
        <Typography.Text strong>{title}</Typography.Text>
        <Typography.Paragraph>{value}</Typography.Paragraph>
      </Col>
    </Row>
  );
};
