import React from "react";

import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { range } from "lodash";

export const HeaderBulletin: React.FC<{
  photo?: string;
}> = ({ photo }) => {
  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
    },
    country: {
      textTransform: "uppercase",
      textAlign: "center",
    },
  });

  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={{ width: 60, borderRight: "1px solid #000" }}>
          <Image
            style={{ width: "100%" }}
            src={require("../../assets/images/flag.png")}
          />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid #000",
          }}
        >
          <Text style={styles.country}>
            {`république décomocratique du congo`}
          </Text>
          <Text style={[styles.country, { fontSize: 11 }]}>
            {`minisère de l'enseignement primaire, secondaire et technique`}
          </Text>
        </View>
        <View style={{ width: 50 }}></View>
      </View>
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          borderTop: "1px solid #000",
        }}
      >
        <View>
          <Text style={{ padding: "5px" }}>{`N° ID`}</Text>
        </View>
        <View
          style={{ flexDirection: "row", alignItems: "center", padding: 5 }}
        >
          {range(0, 15).map((p) => (
            <View
              style={{ width: 20, height: 20, border: "1px solid #000" }}
            ></View>
          ))}
        </View>
      </View>
    </React.Fragment>
  );
};
