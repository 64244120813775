import { Button, Col, Modal, notification, Row } from "antd";
import { FormikValues, useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import * as yup from "yup";
import { DlInput } from "../../components/input";
import registerService from "../../services/registerService";
import userService from "../../services/userService";
import { getPhoneValue } from "../../utils/geoHelper";
import { phoneRegex } from "../../utils/text";
import { CreateTeacherLevel } from "./addLevel";

interface createTeacherInterface {
  onRelaod?: any;
  user?: any;
  uid?: string;
}

export function CreateTeacher(props: createTeacherInterface) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showLevel, setShowLevel] = React.useState(false);
  const [user, setUser] = React.useState<any>({});
  const formik = useFormik({
    initialValues: {
      firstName: props.user?.firstName ?? "",
      lastName: props.user?.lastName ?? "",
      phone: props.user?.phone ?? "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      phone: yup
        .string()
        .required("Champ requis")
        .matches(phoneRegex, { message: "Numéro de téléphone invalide" }),
    }),
    onSubmit: (values) => handleSave(values),
  });
  function handleShow() {
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);
  }

  React.useEffect(() => {
    const current = props.user;
    if (!isEmpty(current)) {
      formik.setValues({
        ...formik.values,
        firstName: current.firstName ?? "",
        lastName: current.lastName ?? "",
        phone: current.phone ?? "",
      });
    }
  }, [props.uid, props.user]);

  async function handleSave(values: FormikValues) {
    setLoading(true);
    const { firstName, lastName, phone } = values;
    const phoneNumber = getPhoneValue(phone);
    const country = phoneNumber.getCallingCode() ?? "243";
    const data = {
      firstName,
      lastName,
      phone: phone,
      country: country,
      isTeacher: true,
    };
    await userService
      .store(data)
      .then(async (response) => {
        if (typeof props.onRelaod === "function") {
          props.onRelaod();
        }
        console.log("TEACHER", response.data);
        setUser(response.data);
        Modal.info({
          title: "Création de compte enseignant",
          centered: true,
          content: (
            <div>
              <h5>{`Un enseignant a été ajouté`}</h5>
              <p>{`Voulez-vous ajouter des niveaux pour cet enseignant?`}</p>
            </div>
          ),
          okText: "Continuer",
          cancelText: "Ajouter plus tard",
          okCancel: true,
          onOk: () => {
            formik.resetForm();
            setShowLevel(true);
          },
          onCancel: () => {
            formik.resetForm();
          },
        });
        setVisible(false);
      })
      .catch((reason) => {
        notification.error({
          message: "Enregistrement",
          description: "L'enregistrement a échoué",
        });
      });
    setLoading(false);
  }

  return (
    <React.Fragment>
      <Button onClick={handleShow} type="primary" title="ajouter un enseignant">
        Ajouter un enseignant
      </Button>
      <Modal
        visible={visible}
        centered
        onCancel={handleClose}
        title="Insertion de l'enseignant"
        confirmLoading={loading}
        onOk={() => formik.handleSubmit()}
        okText="Ajouter"
        cancelText="Annuler"
      >
        <div>
          <Row gutter={8}>
            <Col flex={1}>
              <DlInput
                formik={formik}
                label="Nom"
                name="lastName"
                type="text"
                placeholder="Le nom de l'enseignant"
              />
            </Col>
            <Col flex={1}>
              <DlInput
                formik={formik}
                label="Prénom"
                name="firstName"
                type="text"
                placeholder="Le prénom de l'enseignant"
              />
            </Col>
          </Row>
          <div>
            <DlInput
              label="Numéro de téléphone"
              name="phone"
              formik={formik}
              placeholder="+243000000000"
              type="tel"
            />
          </div>
        </div>
      </Modal>
      <CreateTeacherLevel
        setVisible={setShowLevel}
        visible={showLevel}
        user={user}
        onReload={props.onRelaod}
      />
    </React.Fragment>
  );
}
