import React from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Popover,
  Result,
  Row,
  Tag,
  Typography,
} from "antd";
import { isEmpty, upperFirst } from "lodash";
import { getGroupTitle, getUid } from "../../utils/helpers/title";
import { CreateGroup } from "../group/create";
import groupService from "../../services/groupService";
import levelService from "../../services/levelService";
import { HelpOutline } from "@material-ui/icons";

interface structGroup {
  item: any;
  parent: any;
  setCurrentGroup?: any;
}

export function ShowingStruct(props: structGroup) {
  const { item, parent, setCurrentGroup } = props;
  const [levels, setLevels] = React.useState<any[]>([]);
  const [count, setCount] = React.useState<number>(0);

  React.useEffect(() => {
    getLevels();
  }, [item]);

  async function getGroup(uid: string) {
    let rep = null;
    await groupService
      .get(uid)
      .then((response) => {
        rep = response.data;
      })
      .catch(() => {});
    return rep;
  }

  function getTitle(type: string) {
    switch (type) {
      case "section":
        return "Ajouter une option";
      case "department":
        return "Ajouter une option";

      default:
        return "Impossible d'ajouter une structure";
    }
  }

  async function getLevels() {
    await levelService
      .getByKey(`groups=${getUid(item["@id"])}&limit=1000&order[promotion]=asc`)
      .then((response) => {
        const data = levelService.getData(response);
        const count = levelService.getTotal(response);
        setLevels(data);
        setCount(count);
      })
      .catch(() => {});
  }

  async function init() {
    const uid = parent?.uid;
    if (!isEmpty(uid) && typeof setCurrentGroup === "function") {
      const rep = await getGroup(uid);
      if (!isEmpty(rep)) {
        setCurrentGroup(rep);
      }
    }
  }

  return (
    <Layout
      style={{
        width: "100%",
        background: "#fff",
        height: "80vh",
        borderLeft: "#f1f1f144 .2px solid",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={
            isEmpty(item)
              ? {
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : { flex: 1 }
          }
        >
          {isEmpty(item) && (
            <Result status="warning" title="Aucun élément sélectionné" />
          )}
          {!isEmpty(item) && (
            <div className="yc_option_showin">
              <div style={{ textAlign: "center" }}>
                <Typography.Title
                  level={2}
                  className="yc_h2"
                  style={{
                    padding: "20px 0 0 0",
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  {upperFirst(item.name)}
                </Typography.Title>
                {!isEmpty(getUid(item["@id"])) && (
                  <Typography.Text
                    strong
                    style={{ textAlign: "center", opacity: 0.6 }}
                  >
                    {"Type de groupement : "}
                    {getGroupTitle(item)}
                  </Typography.Text>
                )}
              </div>
              <div style={{ padding: "20px 10px" }}>
                <Card className="card">
                  <div style={{ textAlign: "center" }}>
                    <Avatar>{count}</Avatar>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ padding: "0 10px" }}>{`Niveau${
                        count > 0 ? "x" : ""
                      }`}</span>
                      <Popover
                        title="C'est quoi un niveau?"
                        placement="bottom"
                        content="Le niveau représente une classe (en parlant des écoles), ou une promotion (en parlant des universités et insituts)."
                      >
                        <HelpOutline />
                      </Popover>
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {levels.map((p: any, index: number) => (
                      <Tag key={`tag_${index}`}>{p.title}</Tag>
                    ))}
                  </div>
                </Card>
              </div>
              <div style={{ padding: 20, textAlign: "center" }}>
                {!(
                  isEmpty(item.type) ||
                  item.type === "option" ||
                  item.type === "option_class"
                ) && (
                  <CreateGroup
                    id={item.uid}
                    init={init}
                    label={getTitle(item.type)}
                    type={item.type}
                    onlyValue={
                      item.type === "section"
                        ? "option_class"
                        : item.type === "department"
                        ? "option"
                        : ""
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
