import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import studentService from "../../services/studentService";
import { setStudent } from "../../redux/studentReducer";
import { Skeleton, Tabs } from "antd";
import userHelper from "../../utils/helpers/userHelper";
import resultService from "../../services/resultService";
import { setResults } from "../../redux/resultReducer";
import levelService from "../../services/levelService";
import { setLevel } from "../../redux/levelReducer";
import etsHelper from "../../utils/helpers/etsHelper";
import subjectService from "../../services/subjectService";
import { setSubjects } from "../../redux/subjectReducer";
import { PageHeader } from "@ant-design/pro-components";
import { setCurrentStudent } from "../../redux/studentReducer";
import { setPeriod } from "../../redux/periodReducer";
import { groupBy, isEmpty, sumBy } from "lodash";
import { DashboardStudentView } from "./dashboard";
import { StatStudentView } from "./stats";
import { PeriodStudentView } from "./period";
import { BulletinComponent } from "../../components/bulletinComponent";

export function StudentPrintView() {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const student = useSelector((state: RootState) => state.student.current);
  const level = useSelector((state: RootState) => state.level.current);
  const period = useSelector((state: RootState) => state.period.current);
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const subjects = useSelector((state: RootState) => state.subject.list);
  const results = useSelector((state: RootState) => state.result.list);
  const dispatch = useDispatch();
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    init();
  }, [params]);

  React.useEffect(() => {
    const elements: any[] = [...subjects] ?? [];
    const max = sumBy(elements, (p) => p.point ?? 0);
    const items: any[] = [];
    let courses: any = elements?.sort((a: any, b: any) => a.point - b.point);
    courses = groupBy(courses, (p: any) => p.point);
    let sum = 0;
    for (const key in courses) {
      items.push({
        branch: "MAXIMA",
        period: key,
        styleText: {
          period: { fontFamily: "calibri-bold", fontSize: 12 },
          branch: { fontFamily: "calibri-bold" },
        },
      });
      const list: any[] = courses[key] ?? [];
      list.map((p) => {
        const result = results.find(
          (x) =>
            x?.student?.uid === student?.uid &&
            x?.course?.uid === p.uid &&
            x?.period?.uid === period?.uid
        );
        let wrong = false;
        if (result?.point > 0) {
          const maxPoint = result?.max ?? 0;
          if (maxPoint > 0) {
            const percent = (result?.point / maxPoint) * 100;
            wrong = percent < 50;
          }
          sum += result?.point;
        } else {
          wrong = true;
        }
        const item: any = { branch: p.title, period: result?.point ?? "---" };
        if (wrong) {
          item.styleText = {
            period: { color: "red" },
          };
        }
        items.push(item);
      });
    }
    items.push({
      branch: "TOTAL",
      period: `${sum}/${max}`,
      styleText: {
        period: { fontFamily: "calibri-bold", fontSize: 12 },
        branch: { fontFamily: "calibri-bold" },
      },
    });
    if (max > 0) {
      items.push({
        branch: "POURCENTAGE",
        period: `${((sum / max) * 100).toFixed(2)}%`,
        styleText: {
          period: { fontFamily: "calibri-bold", fontSize: 12 },
          branch: { fontFamily: "calibri-bold" },
        },
      });
    }
    setData(items);
  }, [subjects, results, period]);

  async function init() {
    const uid = params.id;
    setLoading(true);
    await studentService
      .get(`${uid}`)
      .then(async (response) => {
        const result = response.data;
        dispatch(setCurrentStudent(result));
        await getLevel(result?.level?.uid ?? "");
        await getCourses(result?.level?.uid ?? "");
      })
      .catch(() => {});
    await getResults(establishment.edition.uid, `${uid}`);
    setLoading(false);
  }

  async function getResults(edition: string, student: string) {
    let result: any[] = [];
    await resultService
      .getByKey(`edition=${edition}&student=${student}&limit=100000`)
      .then((response) => {
        result = resultService.getData(response);
        dispatch(setResults(result));
      })
      .catch(() => {});
    return result;
  }

  async function getCourses(level: string) {
    let result: any[] = [];
    await subjectService
      .getByKey(`level=${level}&limit=100000&order[title]=asc`)
      .then((response) => {
        result = subjectService.getData(response);
        dispatch(setSubjects(result));
      })
      .catch(() => {});
    return result;
  }

  async function getLevel(uid: string) {
    let result: any = {};
    await levelService
      .get(uid)
      .then(async (response) => {
        result = response.data;
        const per = await etsHelper.getCurrentPeriod(result.groups);
        dispatch(setLevel(result));
        dispatch(setPeriod(per));
      })
      .catch(() => {});
    return result;
  }

  return (
    <React.Fragment>
      <div className="box">
        {loading && (
          <React.Fragment>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            <div>
              <div>{userHelper.getUserName(student.users)}</div>
            </div>
            <PageHeader
              onBack={() => window.history.back()}
              title={userHelper.getUserName(student.users)}
            ></PageHeader>
            <BulletinComponent
              cardColumns={[
                { dataIndex: "branch", title: "Branches" },
                { dataIndex: "period", title: period.title },
              ]}
              cardData={data}
              info={{
                fullName: userHelper.getUserName(student.users),
                province: "Kinshasa",
                school: establishment?.name,
                classe: `${level.title ?? ""} ${level?.groups?.name ?? ""} ${
                  level?.reference ?? ""
                }`,
                edition: establishment?.edition?.name ?? "",
              }}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
