import { UserOutlined } from "@ant-design/icons";
import { Avatar, Table, Typography } from "antd";
import { upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { upper } from "../../utils/text";

export function TeacherLevel() {
  const teachers = useSelector((state: RootState) => state.level.teachers);

  return (
    <React.Fragment>
      <Table
        dataSource={teachers}
        columns={[
          {
            title: "",
            dataIndex: "teacher",
            render(value, record, index) {
              return (
                <Avatar>
                  <UserOutlined />
                </Avatar>
              );
            },
            width: 50,
          },
          {
            title: "Nom de l'enseignant",
            dataIndex: "teacher",
            render(value, record, index) {
              return (
                <Typography.Text>
                  {`${upperFirst(value?.users?.firstName)} ${upper(
                    value?.users?.lastName
                  )}`}
                </Typography.Text>
              );
            },
          },
        ]}
      />
    </React.Fragment>
  );
}
