import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row } from "antd";
import { groupBy, isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupComponent, GroupElement } from "../components/navigation/group";
import { setUserGroups } from "../redux/userReducer";
import groupService from "../services/groupService";
import { GroupDashboard } from "./dashboard/group";

export function OrganizationScreen() {
  const groups = useSelector((state) => state.user.groups);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState({
    root: false,
    department: false,
    faculty: false,
    cycle: false,
  });
  const [steps, setSteps] = React.useState(["root"]);
  const [faculty, setFaculty] = React.useState({});
  const [cycle, setCycle] = React.useState({});
  const [department, setDepartment] = React.useState({});
  const [option, setOption] = React.useState({});
  const [section, setSection] = React.useState({});
  const [optionClass, setOptionClass] = React.useState({});
  const [click, setClick] = React.useState(null);

  const [cycles, setCycles] = React.useState([]);
  const [faculties, setFaculties] = React.useState([]);

  React.useEffect(() => {
    if (groups.length === 0) {
      getGroups();
    }
  }, []);

  async function getGroups() {
    await groupService
      .getByKey(`type[]=cycle&type[]=faculty`)
      .then((response) => {
        const data = groupService.getData(response);
        dispatch(setUserGroups(data));
      })
      .catch(() => {});
  }

  React.useEffect(() => {
    const school = ["cycle", "section", "option_school"];
    const university = ["faculty", "department", "option"];
    if (!isEmpty(click)) {
      let elements = [];
      switch (click.type) {
        case "faculty":
          elements = ["root"];
          elements.push("faculty");
          setFaculty({
            title: click.name,
            data: click.children,
            id: click["@id"],
          });
          setSteps(
            elements.filter((p) => school.findIndex((q) => q == p) == -1)
          );
          break;
        case "department":
          elements = steps;
          elements.push("department");
          setDepartment({
            title: click.name,
            data: click.children,
            id: click["@id"],
          });
          setSteps(
            elements.filter((p) => school.findIndex((q) => q == p) == -1)
          );
          break;
        case "option":
          elements = steps;
          elements.push("option");
          setOption({
            title: click.name,
            data: click.children,
            id: click["@id"],
          });
          setSteps(
            elements.filter((p) => school.findIndex((q) => q == p) == -1)
          );
          break;
        case "cycle":
          elements = ["root"];
          elements.push("cycle");
          setCycle({
            title: click.name,
            data: click.children,
            id: click["@id"],
          });
          setSteps(
            elements.filter((p) => university.findIndex((q) => q == p) == -1)
          );
          break;
        case "section":
          elements = steps;
          elements.push("section");
          setSection({
            title: click.name,
            data: click.children,
            id: click["@id"],
          });
          setSteps(
            elements.filter((p) => university.findIndex((q) => q == p) == -1)
          );
          break;

        default:
          break;
      }
    }
  }, [click]);

  React.useEffect(() => {
    const items = groupBy(groups, (p) => p.type);
    setCycles(items.cycle ?? []);
    setFaculties(items.faculty ?? []);
  }, [groups]);

  return (
    <Layout className="box">
      <GroupDashboard
        type="all"
        init={getGroups}
        cycles={cycles}
        faculties={faculties}
      />
    </Layout>
  );
}
