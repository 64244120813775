import { Layout } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";

const getRoutes = () => {
  const items = routes.filter(
    (p) => p.layout === "/home" && p.component != null
  );
  return items.map((prop, key) => {
    return <Route path={prop.path} element={<prop.component />} key={key} />;
  });
};

function AuthLayout() {
  return (
    <Layout className="yc-bg-home" style={{ height: "100vh" }}>
      <Routes>{getRoutes()}</Routes>
    </Layout>
  );
}

export { AuthLayout };
