import { Avatar, List, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export function SettingScreen() {
  const [items] = React.useState([
    {
      icon: "fa-info",
      title: "Information de l'école",
      url: "/admin/settings/info",
    },
    {
      icon: "fa-list-check",
      title: "Gestionnaire de périodes",
      url: "/admin/settings/periods",
    },
    {
      icon: "fa-scale-balanced",
      title: "Règles",
      url: "",
    },
    {
      icon: "fa-spell-check",
      title: "Gestionnaire d'évaluations",
      url: "",
    },
    {
      icon: "fa-print",
      title: "Impression",
      url: "/admin/settings/print",
    },
    {
      icon: "fa-books",
      title: "Gestionnaire des notes",
      url: "",
    },
    {
      icon: "fa-sack-dollar",
      title: "Gestionnaire de facturation",
      url: "",
    },
    {
      icon: "fa-message-lines",
      title: "Alertes",
      url: "",
    },
  ]);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Link to={item.url} title={item.title} className="link__setting">
          <Avatar size={80}>
            <i className={`icon__setting fa-solid ${item.icon}`}></i>
          </Avatar>
          <div>
            <Typography.Text strong>{item.title}</Typography.Text>
          </div>
        </Link>
      </List.Item>
    );
  };
  return (
    <div
      className="box"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <List
        renderItem={renderItem}
        dataSource={items}
        grid={{
          gutter: 4,
          column: 4,
        }}
      />
    </div>
  );
}
