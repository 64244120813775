import React from "react";
import { AuthLayout } from "./layouts/auth";
import { AdminLayout } from "./layouts/admin";
import "moment/locale/fr";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "red",
  },
});

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="home/*" element={<AuthLayout />} />
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="/" element={<Navigate to="/home/login" replace />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
