import { configureStore } from "@reduxjs/toolkit";
import branchReducer from "./branchReducer";
import chapiterReducer from "./chapiterReducer";
import etsReducer from "./etsReducer";
import lessonReducer from "./lessonReducer";
import levelReducer from "./levelReducer";
import levelSchoolReducer from "./levelSchoolReducer";
import levelUniversityReducer from "./levelUniversityReducer";
import subjectReducer from "./subjectReducer";
import subscriptionReducer from "./subscriptionReducer";
import teacherReducer from "./teacherReducer";
import userReducer from "./userReducer";
import groupReducer from "./groupReducer";
import studentReducer from "./studentReducer";
import resultReducer from "./resultReducer";
import periodReducer from "./periodReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    subject: subjectReducer,
    lesson: lessonReducer,
    level: levelReducer,
    teacher: teacherReducer,
    school: levelSchoolReducer,
    university: levelUniversityReducer,
    establishment: etsReducer,
    chapter: chapiterReducer,
    branch: branchReducer,
    subscription: subscriptionReducer,
    group: groupReducer,
    student: studentReducer,
    result: resultReducer,
    period: periodReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
