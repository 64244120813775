import { Button, Card, Col, List, Rate, Row, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import React, { PropsWithChildren } from "react";
import { CreateForecast } from "./create";

export const WeekView: React.FC<
  PropsWithChildren<{
    week: number;
    month: number;
    year: number;
    items: any[];
    getForecats?: any;
    subject?: any;
    element?: any;
    monthWeek?: number;
  }>
> = ({
  items,
  month,
  monthWeek,
  week,
  year,
  getForecats,
  subject,
  element,
}) => {
  const renderItem = (item: any) => {
    return (
      <List.Item>
        {/**@ts-ignore */}
        <Card className="card" style={{ width: "100%" }}>
          <Row gutter={32} wrap={false} align="middle">
            <Col flex={1}>
              <Typography.Text
                strong
                style={{ fontSize: 18, textTransform: "uppercase" }}
              >
                {item.title}
              </Typography.Text>
              {/**@ts-ignore */}
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {item.summary}
              </Typography.Paragraph>
            </Col>
            <Col>
              <div>
                <i></i>
                <span>{item?.subject?.title}</span>
              </div>
              <div>
                <i></i>
                <span>{item?.branch?.title}</span>
              </div>
            </Col>
            <Col>
              <Rate disabled />
              <div>
                <Typography.Text>{`Etat de forme`}</Typography.Text>
              </div>
              <Button>{`Voir la prévision`}</Button>
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <section>
        <PageHeader
          onBack={() => window.history.back()}
          title={subject?.title}
          extra={[
            <CreateForecast
              subject={subject}
              item={{
                month,
                year,
                week,
                monthWeek,
              }}
              init={getForecats}
            />,
          ]}
        ></PageHeader>
        <List dataSource={items} renderItem={renderItem} />
      </section>
    </React.Fragment>
  );
};
