import {
  Button,
  Checkbox,
  Col,
  Modal,
  notification,
  Row,
  Typography,
} from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { ErrorAlert } from "../../components/error";
import { DlInput } from "../../components/input";
import userService from "../../services/userService";
import { getPhoneValue } from "../../utils/helpers/geoHelper";

interface createAgentInterface {
  init?: any;
}

export function CreateAgent(props: createAgentInterface) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [] as string[],
      phone: "",
    },
    onSubmit: onCreate,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      phone: yup.string().required("Champ requis"),
      email: yup
        .string()
        .required("Champ requis")
        .email("L'adresse placée n'est pas correcte"),
      roles: yup.array().required("Choisissez au moins un rôle"),
    }),
  });

  const [roles] = React.useState<any[]>([
    { label: "Chargé des finances", value: "FINANCE_ROLES" },
    { label: "Chargé des ressources humaines", value: "AGENT_ROLES" },
    {
      label: "Chargé de l'organisation du système",
      value: "ORGANIZATION_ROLES",
    },
    { label: "Superviseur", value: "SUPERVISOR_ROLES" },
    { label: "Chargé de la pédagogie", value: "PEDAGOGY_ROLES" },
  ]);

  function handleChangeRole(value: string) {
    let elements: string[] = formik.values.roles;
    console.log("STEP 1 ", elements);
    const index = elements.findIndex((p) => p === value);
    if (index === -1) {
      elements.push(value);
    } else {
      elements = elements.filter((p) => p !== value);
    }
    formik.setValues({ ...formik.values, roles: elements });
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  function handleOpen() {
    setVisible(true);
  }

  async function onCreate() {
    setLoading(true);
    const { firstName, lastName, phone, email, roles } = formik.values;
    const phoneNumber = getPhoneValue(phone);
    const country = phoneNumber.getCallingCode() ?? "243";
    const data = {
      firstName,
      lastName,
      email,
      phone: phone,
      country: country,
      responsible: false,
      teacher: false,
      roles,
      password: "123456",
    };
    await userService
      .store(data)
      .then(async (response) => {
        if (typeof props.init === "function") {
          props.init();
        }
        notification.info({
          message: "Création de l'agent",
          description: "Un agent a été ajouté avec succès",
        });
        setVisible(false);
        setLoading(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({ message: "Erreur", description: error });
      });
    setLoading(false);
  }
  return (
    <React.Fragment>
      <Button type="primary" onClick={handleOpen}>
        {`Ajouter`}
      </Button>
      <Modal
        onOk={() => formik.handleSubmit()}
        confirmLoading={loading}
        title="Ajouter un nouvel agent"
        visible={visible}
        onCancel={handleHide}
        okText="Enregistrer"
        cancelText="Annuler"
      >
        <div style={{ margin: "0 20px" }}>
          <Row gutter={8}>
            <Col flex={1}>
              <DlInput
                formik={formik}
                label="Votre nom"
                name="lastName"
                type="text"
              />
            </Col>
            <Col flex={1}>
              <DlInput
                formik={formik}
                label="Votre prénom"
                name="firstName"
                type="text"
              />
            </Col>
          </Row>
          <div>
            <DlInput
              label="Numéro de téléphone"
              name="phone"
              formik={formik}
              placeholder="+243000000000"
              type="tel"
            />
          </div>
          <div>
            <DlInput
              label="Adresse email"
              name="email"
              formik={formik}
              placeholder="your@domain.com"
              type="email"
            />
          </div>
          <div>
            <Typography.Text>{`Définissez les rôles`}</Typography.Text>
            <div>
              {roles.map((p, index) => {
                return (
                  <Checkbox
                    checked={
                      formik.values.roles.findIndex((x) => x === p.value) !== -1
                    }
                    onChange={() => handleChangeRole(p.value)}
                    key={`roles_${index}`}
                  >
                    {p.label}
                  </Checkbox>
                );
              })}
            </div>
            <div>
              {formik.errors.roles && formik.touched.roles && (
                <ErrorAlert message={formik.errors.roles.toString()} />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
