import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { DlInput, DlSelect } from "../../components/input";
import groupService from "../../services/groupService";
import * as yup from "yup";

export function CreateGroup({
  label = "Ajouter un élément",
  title = "",
  type = "",
  onlyValue = "",
  id = null,
  init = {},
}) {
  const [items, setItems] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  console.log("UID", id, type);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: isEmpty(onlyValue) ? null : onlyValue,
      parent: isEmpty(id) ? null : `/api/groups/${id}`,
    },
    onSubmit: handleSave,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      type: yup.string().required("Champ requis"),
    }),
  });

  const onOpen = () => setVisible(true);
  const onClose = () => setVisible(false);

  React.useEffect(() => {
    formik.setValues({ parent: isEmpty(id) ? null : `/api/groups/${id}` });
  }, [id]);

  React.useEffect(() => {
    switch (type) {
      case "root":
        setItems([
          { value: null, title: "Sélectionnez une catégorie" },
          { value: "faculty", title: "Faculté" },
          { value: "cycle", title: "Cycle" },
        ]);
        break;
      case "faculty":
        setItems([
          { value: null, title: "Sélectionnez une catégorie" },
          { value: "department", title: "Departement" },
          { value: "option", title: "Option" },
        ]);
        break;
      case "department":
        setItems([
          { value: null, title: "Sélectionnez une catégorie" },
          { value: "option", title: "option" },
        ]);
        //formik.setValues({type:"option"});
        break;
      case "cycle":
        setItems([
          { value: null, title: "Sélectionnez une catégorie" },
          { value: "section", title: "Section" },
        ]);
        //formik.setValues({type:"section"});
        break;
      case "section":
        setItems([
          { value: null, title: "Sélectionnez une catégorie" },
          { value: "option_class", title: "Option" },
        ]);
        //formik.setValues({type:"option_class"});
        break;

      default:
        break;
    }
  }, [type, onlyValue]);

  async function handleSave() {
    const data = formik.values;
    setLoading(true);
    await groupService
      .store(data)
      .then(() => {
        if (typeof init === "function") {
          init();
        }
        notification.info({
          message: "Enregistrement",
          description: "L'enregistement a réussi",
        });
        setVisible(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = groupService.getError(reason);
        notification.error({
          message: "Echec d'enregistrement",
          description: isEmpty(error) ? "Erreur de réseau" : error,
        });
      });
    setLoading(false);
  }

  return (
    <React.Fragment>
      <Button onClick={onOpen} type="primary">
        <PlusOutlined /> {label}
      </Button>
      <Modal
        visible={visible}
        title={title}
        centered
        confirmLoading={loading}
        okText="Enregistrer"
        cancelText="Annuler"
        onCancel={onClose}
        onOk={() => formik.handleSubmit()}
      >
        <div>
          <DlInput
            name="name"
            label="Le nom du groupe"
            formik={formik}
            placeholder=""
          />
          {(isEmpty(onlyValue) || !isEmpty(onlyValue)) && (
            <DlSelect
              list={items}
              label="Type d'élément"
              formik={formik}
              name="type"
            />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}
