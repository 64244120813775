import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface levelData {
  current: any;
  list: any[];
  teachers: any[];
  students: any[];
  filters: any[];
}

const initialState: levelData = {
  current: {},
  list: [],
  teachers: [],
  students: [],
  filters: [],
};

export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    setCurrentClass: (state, action: PayloadAction<object>) => {
      state.current = action.payload;
    },
    setSchoolLevels: (state, action: PayloadAction<object[]>) => {
      state.list = action.payload;
    },
    setSchoolStudents: (state, action: PayloadAction<any[]>) => {
      state.students = action.payload;
    },
    setSchoolTeachers: (state, action: PayloadAction<any[]>) => {
      state.teachers = action.payload;
    },
    setSchoolFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
  },
});
export const {
  setCurrentClass,
  setSchoolLevels,
  setSchoolStudents,
  setSchoolTeachers,
  setSchoolFilters,
} = schoolSlice.actions;
export default schoolSlice.reducer;
