import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Layout,
  List,
  Modal,
  notification,
  Popover,
  Row,
  Tag,
  Typography,
} from "antd";
import { isEmpty, toInteger, toString, toUpper, upperFirst } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../redux/store";
import {
  setTeacherFilters,
  setTeacherLength,
  setTeachers,
} from "../redux/teacherReducer";
import teacherService from "../services/teacherService";
import { getPhoneValue } from "../utils/geoHelper";
import { getGroupTitle, getUid } from "../utils/helpers/title";
import { CreateTeacher } from "./teacher/createTeacher";

export function TeacherScreen() {
  const teachers = useSelector((state: RootState) => state.teacher.list);
  const filters = useSelector((state: RootState) => state.teacher.filter);
  const itemLength = useSelector((state: RootState) => state.teacher.length);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  async function init() {
    const query: any[] = [];
    query.push({ item: "order[users.firstName]", value: "asc" });
    query.push({ item: "order[users.lastName]", value: "asc" });
    query.push({ item: "limit", value: 10 });
    query.push({ item: "page", value: 1 });
    dispatch(setTeacherFilters(query));
  }

  function getCurrentPage() {
    const elements = filters.filter((p) => p.item === "page");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 1;
    }
  }

  function getCurrentLimit() {
    const elements = filters.filter((p) => p.item === "limit");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 10;
    }
  }

  React.useEffect(() => {
    if (teachers.length === 0) {
      init();
    }
  }, []);

  React.useEffect(() => {
    getTeachers();
  }, [filters]);

  async function getTeachers() {
    let query = ``;
    filters.forEach((p) => {
      query += `${p.item}=${p.value}&`;
    });
    setLoading(true);
    await teacherService
      .getByKey(query)
      .then((response) => {
        const data = teacherService.getData(response);
        const count = teacherService.getTotal(response);
        dispatch(setTeachers(data));
        dispatch(setTeacherLength(count));
      })
      .catch(() => {});
    setLoading(false);
  }

  function handleChangePage(page: number, pageSize: number) {
    const elements = filters.filter(
      (p) => p.item !== "page" && p.item !== "limit"
    );
    elements.push({ item: "page", value: page });
    elements.push({ item: "limit", value: pageSize });
    dispatch(setTeacherFilters(elements));
  }

  const { Text } = Typography;

  async function onDelete(uid: string) {
    await teacherService
      .destroy(uid)
      .then((response) => {
        init();
      })
      .catch((reason) => {
        const error = teacherService.getError(reason);
        notification.error({
          message: "Suppréssion de l'enseignant",
          description: error,
        });
      });
  }

  function handleDelete(uid: string, name: string) {
    Modal.confirm({
      title: "Suppréssion de l'enseignant",
      content: (
        <p>
          Voulez-vous supprimer <b>{name}</b> de façon définitive?
        </p>
      ),
      okText: "Supprimer",
      cancelText: "Annuler",
      onOk: async () => {
        await onDelete(uid);
      },
    });
  }

  const renderItem = (item: any) => {
    console.log("RENDER", item);
    return (
      <List.Item>
        <Row gutter={4} align="middle" wrap={false} style={{ width: "100%" }}>
          <Col span={2}>
            <Avatar size="large" src={item.users?.photo}>
              {isEmpty(item.users?.photo) && <UserOutlined />}
            </Avatar>
          </Col>
          <Col span={8}>
            <div>
              <Text strong>
                {`${upperFirst(item.users?.firstName)} ${toUpper(
                  item.users?.lastName
                )}`}
              </Text>
            </div>
            <div>
              <Text>
                Contact:
                <Text>
                  {getPhoneValue(toString(item.users?.phone)).getNumber()
                    ?.number ?? ""}
                </Text>
              </Text>
            </div>
            {!isEmpty(item.users?.email) && (
              <div>
                <Text>
                  Email:<Text>{item.users?.email}</Text>
                </Text>
              </div>
            )}
          </Col>
          <Col flex={1}>
            {item?.commitments?.length > 0 ? (
              <div>
                {item?.commitments?.map((p: any, index: number) => {
                  if (index < 5) {
                    return (
                      <Tag title={getGroupTitle(p?.level?.groups)}>
                        {p?.level?.title}
                      </Tag>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            ) : (
              <Text strong>{`Aucun niveau n'est défini`}</Text>
            )}
          </Col>
          <Col>
            <Popover
              content={
                <React.Fragment>
                  <div>
                    <Button
                      style={{ width: "100%", margin: 2 }}
                      onClick={() =>
                        handleDelete(
                          getUid(item["@id"]),
                          `${upperFirst(item.users?.firstName)} ${toUpper(
                            item.users?.lastName
                          )}`
                        )
                      }
                      danger
                      title="Supprimer définitivement"
                    >
                      {`Supprimer`}
                    </Button>
                  </div>
                  <div>
                    <Button type="primary" style={{ width: "100%", margin: 2 }}>
                      <Link to={`/admin/teacher/${getUid(item["@id"])}/view`}>
                        {`Afficher`}
                      </Link>
                    </Button>
                  </div>
                </React.Fragment>
              }
            >
              <Button shape="circle" type="primary">
                <i className="fa-solid fa-angle-right"></i>
              </Button>
            </Popover>
          </Col>
        </Row>
      </List.Item>
    );
  };

  return (
    <Layout className="box">
      <Row gutter={8} align="middle">
        <Col flex={1}>
          <Typography.Title>{`Tous les enseignants`}</Typography.Title>
        </Col>
        <Col>
          <CreateTeacher onRelaod={getTeachers} />
        </Col>
      </Row>
      <List
        loading={loading}
        dataSource={teachers}
        renderItem={renderItem}
        pagination={{
          current: getCurrentPage(),
          showSizeChanger: true,
          disabled: loading,
          defaultCurrent: 1,
          pageSize: getCurrentLimit(),
          total: itemLength,
          onChange: handleChangePage,
        }}
      />
    </Layout>
  );
}
