import { isEmpty } from "lodash";
import { setProfilEstablishement } from "../../redux/etsReducer";
import periodService from "../../services/periodService";
import etsService from "../../services/etsService";
import userHelper from "./userHelper";

class EtsHelper {
  private key: string = "maktab_establishment";

  async setEstablishment(value: any, dispatch?: any) {
    let ets: any = {};
    await etsService
      .get(value.uid)
      .then((response) => {
        ets = response.data;
      })
      .catch(() => {});
    localStorage.setItem(this.key, JSON.stringify(ets));
    if (typeof dispatch === "function") {
      dispatch(setProfilEstablishement(ets));
    }
  }

  setLogout() {
    localStorage.removeItem(this.key);
  }

  getEstablishment(): null | any {
    let item = localStorage.getItem(this.key);
    if (!isEmpty(item)) {
      item = JSON.parse(item ?? "");
    }
    return item;
  }

  getMetaValue(
    key: string,
    elements: any[],
    mutiple?: boolean
  ): string | string[] {
    const items = elements.filter((p) => p?.metaKey === key);
    if (true === mutiple) {
      const responses: string[] = [];
      items.forEach((p) => {
        responses.push(p?.metaValue ?? "");
      });
      return responses;
    }
    if (items.length > 0) {
      const item = items[0] ?? {};
      return item?.metaValue ?? "";
    }
    return "";
  }

  getGroupName(value: string) {
    let result = "";
    switch (value) {
      case "cetb":
        result = "études de base";
        break;
      case "primary":
        result = "école primaire";
        break;
      case "humanity":
        result = "Les humanités";
        break;
      default:
        result = value;
        break;
    }
    return result;
  }

  async getCurrentPeriod(group: any) {
    let result: { title: string; uid: string; type: string } = {
      title: "",
      uid: "",
      type: "",
    };
    const elements: any[] = this.getGroups(group);
    const element = elements[elements.length - 1] ?? {};
    if (!isEmpty(element)) {
      if (element.type === "cycle") {
        const uid = element.uid;
        await periodService
          .getByKey(`groups=${uid}&isActive=true`)
          .then((response) => {
            const data = periodService.getData(response);
            data.forEach((p) => {
              if (p.isActive) {
                result.title = p.title;
                result.uid = p.uid;
                result.type = p.category;
              }
            });
          });
      }
    }
    return result;
  }

  async getAllPeriods(group: any) {
    let result: any[] = [];
    const elements: any[] = this.getGroups(group);
    const element = elements[elements.length - 1] ?? {};
    if (!isEmpty(element)) {
      if (element.type === "cycle") {
        const uid = element.uid;
        await periodService
          .getByKey(`groups=${uid}&order[priority]=asc`)
          .then((response) => {
            const data = periodService.getData(response);
            result = data;
          });
      }
    }
    return result;
  }

  private getGroups(group: any) {
    const elements: any[] = [];
    elements.push(group);
    if (!isEmpty(group?.parent)) {
      const subElements = this.getGroups(group?.parent);
      subElements.forEach((p) => {
        elements.push(p);
      });
    }
    return elements;
  }

  async initData(dispatch?: any) {
    await userHelper.getAllStudents(dispatch);
  }
}

export default new EtsHelper();
