import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import studentService from "../../services/studentService";
import { setStudent } from "../../redux/studentReducer";
import { Skeleton, Tabs } from "antd";
import userHelper from "../../utils/helpers/userHelper";
import resultService from "../../services/resultService";
import { setResults } from "../../redux/resultReducer";
import levelService from "../../services/levelService";
import { setLevel } from "../../redux/levelReducer";
import etsHelper from "../../utils/helpers/etsHelper";
import subjectService from "../../services/subjectService";
import { setSubjects } from "../../redux/subjectReducer";
import { PageHeader } from "@ant-design/pro-components";
import { setCurrentStudent } from "../../redux/studentReducer";
import { setPeriod, setPeriods } from "../../redux/periodReducer";
import { isEmpty } from "lodash";
import { DashboardStudentView } from "./dashboard";
import { StatStudentView } from "./stats";
import { PeriodStudentView } from "./period";

export function StudentView() {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const student = useSelector((state: RootState) => state.student.current);
  const level = useSelector((state: RootState) => state.level.current);
  const period = useSelector((state: RootState) => state.period.current);
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, [params]);

  async function init() {
    const uid = params.id;
    setLoading(true);
    await studentService
      .get(`${uid}`)
      .then(async (response) => {
        const result = response.data;
        dispatch(setCurrentStudent(result));
        await getLevel(result?.level?.uid ?? "");
        await getCourses(result?.level?.uid ?? "");
      })
      .catch(() => {});
    await getResults(establishment.edition.uid, `${uid}`);

    setLoading(false);
  }

  async function getResults(edition: string, student: string) {
    let result: any[] = [];
    await resultService
      .getByKey(`edition=${edition}&student=${student}&limit=100000`)
      .then((response) => {
        result = resultService.getData(response);
        dispatch(setResults(result));
      })
      .catch(() => {});
    return result;
  }

  async function getCourses(level: string) {
    let result: any[] = [];
    await subjectService
      .getByKey(`level=${level}&limit=100000&order[title]=asc`)
      .then((response) => {
        result = subjectService.getData(response);
        dispatch(setSubjects(result));
      })
      .catch(() => {});
    return result;
  }

  async function getLevel(uid: string) {
    let result: any = {};
    await levelService
      .get(uid)
      .then(async (response) => {
        result = response.data;
        const per = await etsHelper.getCurrentPeriod(result.groups);
        const periods = await etsHelper.getAllPeriods(result.groups);
        dispatch(setLevel(result));
        dispatch(setPeriod(per));
        dispatch(setPeriods(periods));
      })
      .catch(() => {});
    return result;
  }

  return (
    <React.Fragment>
      <div className="box">
        {loading && (
          <React.Fragment>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </React.Fragment>
        )}
        {!loading && (
          <React.Fragment>
            <div>
              <div>{userHelper.getUserName(student.users)}</div>
            </div>
            <PageHeader
              onBack={() => window.history.back()}
              title={userHelper.getUserName(student.users)}
            ></PageHeader>
            <Tabs>
              <Tabs.TabPane tab="Présentation" key="show">
                <DashboardStudentView />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Stats" key="stat">
                <StatStudentView />
              </Tabs.TabPane>
              {!isEmpty(period.uid) && (
                <Tabs.TabPane tab={`${period.title}`} key="cotation">
                  <PeriodStudentView />
                </Tabs.TabPane>
              )}
            </Tabs>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
