import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Layout,
  notification,
  Row,
  Typography,
} from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { DlInput, DlPassword, DlTextarea } from "../components/input";
import userService from "../services/userService";
import workService from "../services/workService";
import userHelper from "../utils/helpers/userHelper";
import { RootState } from "../redux/store";
import etsService from "../services/etsService";
import establishmentService from "../services/establishmentService";
import etsHelper from "../utils/helpers/etsHelper";

export function EstablishmentConnectScreen() {
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  const [cycles, setCycles] = React.useState<any[]>([]);
  const [visible, setVisible] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profil);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "school",
      address: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
    }),
    onSubmit: onRegister,
  });

  async function getWork() {
    let item = userHelper.getData();
    if (isEmpty(item.uid)) {
      window.location.href = "/home/login";
      return;
    } else {
      userHelper.setData(item, dispatch);
    }
    setRefresh(true);
    await workService
      .getByKey(`user=${item.uid}`)
      .then(async (response) => {
        const elements = workService.getData(response);
        if (elements.length > 0) {
          const element = elements[0];
          await etsHelper.setEstablishment(element.establishment);
          window.location.href = "/admin";
        } else {
          setVisible(true);
        }
      })
      .catch(() => {
        setVisible(true);
      });
    setRefresh(false);
  }

  async function getEts(uid: string) {
    let result: any[] = [];
    await establishmentService
      .get(uid)
      .then((response) => {
        result = establishmentService.getData(response);
      })
      .catch((reason) => {
        const error = workService.getError(reason);
      });
    return result;
  }

  async function onRegister() {
    const { name, type } = formik.values;
    const data = { name, type, cycles, user: user.uid };
    setLoading(true);
    await etsService
      .store(data)
      .then(async (response) => {
        const uid = response.data.uid;
        const ets = await getEts(uid);
        etsHelper.setEstablishment(ets);
        notification.info({
          duration: 3,
          message: "Connexion au compte",
          description:
            "Un compte administrateur est créé. Veuillez vous connecter",
        });
        window.location.href = "/admin";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          description: error,
          message: "Création de compte",
        });
        setLoading(false);
      });
  }

  function onCheckCycle(value: any) {
    setCycles(value);
  }

  React.useEffect(() => {
    getWork();
  }, []);

  return (
    <Layout
      style={{
        display: visible ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 0, padding: 0, background: "#fff" }}
        className="yc-card yc-fade-in"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez créer un compte administrateur`}
        </p>
        <div style={{ padding: "5px 15px 20px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="Le nom de votre école ici"
              type="text"
              formik={formik}
              name="name"
              label="Nom de l'école"
            />
          </div>
          <div style={{ paddingTop: 5 }}>
            <DlTextarea
              placeholder="avenue, quartier, commune, ville, etc."
              type="text"
              formik={formik}
              name="address"
              label="Adresse de l'école"
            />
          </div>
          <Divider />
          <div>
            <Typography.Text italic type="warning">
              {`Veuillez choisir au moins une valeur`}
            </Typography.Text>
          </div>
          <Checkbox.Group
            options={[
              { label: "Primaire", value: "primary" },
              { label: "Education de base", value: "cetb" },
              { label: "Humanité", value: "humanity" },
            ]}
            onChange={onCheckCycle}
          />
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button disabled={loading} onClick={() => {}} type="ghost">
                {`Annuler`}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Sauvegarder`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
