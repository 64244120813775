import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUniversityLevels } from "../redux/levelUniversityReducer";
import { RootState } from "../redux/store";
import levelService from "../services/levelService";
import { getLevelData, LevelScreen } from "./listLevels";

export function UniversityScreen() {
  const levels = useSelector((state: RootState) => state.university.list);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    if (levels.length > 0) {
      return;
    }
    let query = `groups.type[]=faculty&groups.type[]=department&groups.type[]=option`;
    query += `&order[groups.type]=asc&order[promotion]=asc&limit=10`;
    setLoading(true);
    await levelService
      .getByKey(query)
      .then((response) => {
        const data = levelService.getData(response);
        const items = getLevelData(data);
        dispatch(setUniversityLevels(items));
      })
      .catch((reason) => {});
    setLoading(false);
  }

  return (
    <LevelScreen
      levels={levels}
      loading={loading}
      type="university"
      init={init}
    />
  );
}
