import { Col, Layout, Row } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { AdminNavbar } from "../components/navigation/adminNavbar";
import { Sidebar } from "../components/navigation/sidebar";
import { SiderAdmin } from "../components/navigation/siderAdmin";
import { ActualityComponent } from "../components/organism/actuality";
import routes from "../routes";
import S from "../storage/actions.json";
import { DataService } from "../storage/service/data";
import { getToken } from "../utils/helpers/title";
import "./admin.scss";
import { RootState } from "../redux/store";
import userHelper from "../utils/helpers/userHelper";
import etsHelper from "../utils/helpers/etsHelper";

const getRoutes = (routes = []) => {
  return routes
    .filter((p) => p.component != null)
    .map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      } else {
        return null;
      }
    });
};

function AdminLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profil);
  const establishment = useSelector((state) => state.establishment.profil);
  const token = getToken();
  if (isEmpty(token)) {
    window.location.href = "/home/login";
  }

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    if (isEmpty(user)) {
      const item = userHelper.getData();
      if (!isEmpty(item)) {
        await userHelper.setData(item, dispatch);
      }
    }
    if (isEmpty(establishment)) {
      const item = etsHelper.getEstablishment();
      if (!isEmpty(item)) {
        await etsHelper.setEstablishment(item, dispatch);
      }
    }
    await etsHelper.initData(dispatch);
  }

  return (
    <React.Fragment>
      <AdminNavbar />
      <Layout.Content
        id="primary"
        style={{ background: "#fff", padding: 0, overflowX: "hidden" }}
      >
        <SiderAdmin current={location.pathname} />
        <Layout className="yc_container_main">
          <Row gutter={16} wrap={false} className="yc_main">
            <Col flex="1">
              <div className="bg-light">
                <Routes>{getRoutes(routes)}</Routes>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout.Content>
    </React.Fragment>
  );
}

export { AdminLayout };
