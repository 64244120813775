import { setBranchs } from "../../redux/branchReducer";
import { setChapiters } from "../../redux/chapiterReducer";
import branchService from "../../services/branchService";
import chapiterService from "../../services/chapiterService";

class SubjectHelper {
  async getChapters(uid: string, dispatch?: any) {
    let items: any[] = [];
    await chapiterService
      .getByKey(`subject=${uid}&limit=1000&order[priority]=asc`)
      .then((response) => {
        items = chapiterService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setChapiters(items));
        }
      })
      .catch(() => {});
    return items;
  }
  async getBranchs(uid: string, dispatch?: any) {
    let items: any[] = [];
    await branchService
      .getByKey(`subject=${uid}&limit=1000&order[title]=asc`)
      .then((response) => {
        items = branchService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setBranchs(items));
        }
      })
      .catch(() => {});
    return items;
  }
}

export default new SubjectHelper();
