import { Col, List, Row, Switch, Typography } from "antd";
import React from "react";
import periodService from "../../services/periodService";

export const PeriodView: React.FC<{ cycleUid: string }> = ({ cycleUid }) => {
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [active, setActive] = React.useState<string>("");

  async function init() {
    await periodService
      .getByKey(`groups=${cycleUid}&order[priority]=asc`)
      .then((response) => {
        const data = periodService.getData(response);
        setItems(data);
        data.forEach((p) => {
          if (p.isActive) {
            setActive(p.uid);
          }
        });
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  async function onChange(uid: string) {
    if (active === uid) {
      setActive("");
    } else {
      setActive(uid);
      periodService
        .update(uid, { isActive: true })
        .then((response) => {
          console.log("DATA", response.data);
        })
        .catch(() => {});
    }
  }

  const renderItem = (item: any) => {
    return (
      <List.Item className="card" style={{ cursor: "pointer" }}>
        <Row gutter={16} style={{ padding: "16px 16px" }}>
          <Col>
            <Typography.Title level={2} style={{ margin: 0, fontSize: 18 }}>
              {item.position}{" "}
              <sup>
                {item.position === 1
                  ? `èr${item.type === "period" ? "e" : ""}`
                  : `ème`}
              </sup>
              {` ${
                item.type === "period"
                  ? "Période"
                  : item.type === "semestre"
                  ? "Semestre"
                  : item.type === "trimestre"
                  ? "Trimestre"
                  : ""
              }`}
            </Typography.Title>
          </Col>
          <Col>
            <Switch
              checked={active === item.uid}
              onChange={(v) => onChange(item.uid)}
            />
          </Col>
        </Row>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <div>
        <section style={{ maxWidth: "90%", margin: "auto", padding: "0 16px" }}>
          <List
            dataSource={items}
            renderItem={renderItem}
            loading={loading}
            grid={{ gutter: 16, column: 3 }}
          />
        </section>
      </div>
    </React.Fragment>
  );
};
