import {
  Avatar,
  Card,
  Col,
  Layout,
  List,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { isEmpty, toNumber, upperCase } from "lodash";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { setLocale } from "yup";
import Calendar from "../components/calendar";
import { MonthCard } from "../components/card/month";
import forecastService from "../services/forecastService";
import subjectService from "../services/subjectService";
import dateHelpers from "../utils/helpers/dateHelpers";
import { CreateForecast } from "./forecast/create";

export function ForecastView() {
  const [item, setItem] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [subject, setSubject] = React.useState<any>({});
  const [items, setItems] = React.useState<any[]>([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(0);
  const params = useParams();

  async function init() {
    const date = new Date();
    const elements: any[] = [];
    setYear(toNumber(dateHelpers.getDate(date, "YYYY")));
    setMonth(toNumber(dateHelpers.getDate(date, "MM")));
    setloading(true);
    for (let index = 1; index <= 12; index++) {
      const count = await getForecats(index);
      const d = new Date(year, index - 1);
      elements.push({
        month: dateHelpers.getDate(d, "MMMM YYYY"),
        year: year,
        count: count,
        index: index,
      });
    }
    setItems(elements);
    setloading(false);
  }

  async function getForecats(m: number) {
    let count = 0;
    await forecastService
      .getByKey(`month=${m}&year=${year}`)
      .then((response) => {
        count = forecastService.getTotal(response);
      })
      .catch(() => {});
    return count;
  }

  React.useEffect(() => {
    init();
  }, [params, year]);

  function handleClickWeek() {}

  React.useEffect(() => {
    if (item.week > 0 && !isEmpty(subject.uid)) {
      setVisible(true);
    }
  }, [item]);

  const renderItem = (p: any, index: number) => {
    return (
      <List.Item style={{ cursor: "pointer" }}>
        <MonthCard
          count={p.count}
          index={p.index}
          title={p.month}
          year={year}
          key={`card_month_${index}`}
        />
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <Layout className="box">
        {!loading && (
          <List
            dataSource={items}
            renderItem={renderItem}
            grid={{ lg: 4, xl: 4, xxl: 4, gutter: 16 }}
          />
        )}
        {loading && (
          <React.Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </React.Fragment>
        )}
      </Layout>
      {/*<CreateForecast
        setVisible={setVisible}
        visible={visible}
        subject={params.id}
        item={item}
        init={getForecats}
  />*/}
    </React.Fragment>
  );
}
