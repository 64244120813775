import React from "react";
import {
  Document,
  StyleSheet,
  Page,
  View,
  Text,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { HeaderBulletin } from "./pdf/header";
import { IdentityBulletin } from "./pdf/identity";
import { TableView, tabColum } from "./pdf/table";

export const BulletinComponent: React.FC<{
  cardColumns: tabColum[];
  cardData: any[];
  info: any;
}> = ({ cardColumns, cardData, info }) => {
  const styles = StyleSheet.create({
    page: {
      padding: "1cm 1cm",
      backgroundColor: "#fff",
    },
    container: {
      border: "1px solid #000",
    },
  });

  const [columns, setColumns] = React.useState<tabColum[]>([]);

  React.useEffect(() => {
    setColumns(cardColumns);
  }, [cardColumns]);

  return (
    <React.Fragment>
      <section>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="MAKTAB" creator="DEV">
            <Page size="A4" style={[styles.page]}>
              <View style={styles.container}>
                <HeaderBulletin photo={info.photo} />
                <IdentityBulletin
                  birthDate={info.birthDate}
                  bornPlace={info.birthPlace}
                  classe={info.classe}
                  commune={info.commune}
                  fullName={info.fullName}
                  province={info.province}
                  school={info.school}
                  sex={info.sex}
                  edition={info?.edition}
                />
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <TableView list={cardData} columns={columns} />
                  </View>
                  <View></View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
};
