import React, { PropsWithChildren } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line, ChartProps } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const AreaChart: React.FC<
  PropsWithChildren<{
    data: any[];
    labels: any[];
    title: string;
    color?: string;
  }>
> = ({ data, labels, title, color }) => {
  return (
    <React.Fragment>
      <Line
        data={{
          datasets: [
            {
              data: data,
              fill: true,
              label: title,
              backgroundColor: color,
            },
          ],
          labels: labels,
        }}
        title={title}
        options={{}}
      />
    </React.Fragment>
  );
};
