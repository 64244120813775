import { Card } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { RenderGroup } from "../../components/renderGroup";

interface subjectInterface {
  subject: subjectInterface | any;
}
export function PresentationSubject(props: subjectInterface) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        {isEmpty(props.subject?.description) ?? (
          <Card className="card">
            <Card.Meta
              description={props.subject?.description}
              title="Description du cours"
            />
          </Card>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Card className="card">
          <Card.Meta
            description={props.subject?.level?.title}
            title="Le niveau où le cours est enseigné"
          />
          <RenderGroup group={props.subject?.level?.groups} />
        </Card>
      </div>
    </div>
  );
}
