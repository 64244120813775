import { Button, Col, Layout, List, Row, Table } from "antd";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardMiniSubject } from "../components/card/miniSubject";
import { SubjectCard } from "../components/card/subjectCard";
import subjectService from "../services/subjectService";
import { getUid } from "../utils/text";
import { CreateSubject } from "./subject/create";
import { GoToForecast } from "./subject/goToForecast";
import { UpdatePosterSubject } from "./subject/updatePoster";

export function SubjectView() {
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();

  React.useEffect(() => {
    init();
  }, []);

  async function init() {
    const id = params.id;
    setLoading(true);
    await subjectService
      .getByKey(`level=${id}&order[title]=asc`)
      .then((response) => {
        setSubjects(subjectService.getData(response));
      })
      .catch(() => {});
    setLoading(false);
  }

  const navigate = useNavigate();

  const renderItem = (item: any, index: number) => {
    return (
      <List.Item>
        <SubjectCard
          title={item.title}
          image={
            item.poster ?? `https://picsum.photos/800/600?random=${index + 1}`
          }
          height={200}
          content={item.description}
          width="100%"
          link={`/admin/subjects/${getUid(item["@id"])}/view`}
          extra={[
            { component: <CreateSubject initFunction={init} item={item} /> },
            {
              component: (
                <Button className="yc-btn-left" type="text">
                  <Link to={`/admin/subjects/${getUid(item["@id"])}/view`}>
                    Voir
                  </Link>
                </Button>
              ),
            },
          ]}
        />
      </List.Item>
    );
  };

  function onLink(item: any) {
    navigate(`/admin/subjects/${getUid(item["@id"])}/view`);
  }

  return (
    <Layout className="box">
      <Row style={{ margin: "10px" }}>
        <Col>
          <CreateSubject id={params.id} initFunction={init} />
        </Col>
      </Row>
      <Table
        dataSource={subjects}
        className="table"
        columns={[
          { title: "Titre", dataIndex: "title", align: "center" },
          { title: "Pondération", dataIndex: "point", align: "center" },
          {
            title: "Examen",
            dataIndex: "point",
            align: "center",
            render(value, record, index) {
              return value * 2;
            },
          },
        ]}
        onRow={(item: any) => ({
          onClick: () => onLink(item),
        })}
      />
      {/*<List
        dataSource={subjects}
        loading={loading}
        renderItem={renderItem}
        grid={{ column: 3, gutter: 16 }}
        style={{ margin: "0px 10px", alignItems: "stretch" }}
      />*/}
    </Layout>
  );
}
