import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface studentData {
  profil: any;
  current: any;
  list: any[];
  all: any[];
  teachers: any[];
  readings: any[];
  lessons: any[]; //foreground lesson
  video: any; //foreground video
  poster: any; //foreground video poster
}

const initialState: studentData = {
  profil: {},
  current: {},
  list: [],
  teachers: [],
  readings: [],
  lessons: [],
  video: null,
  poster: null,
  all: [],
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setStudent: (state, action: PayloadAction<any>) => {
      state.profil = action.payload;
    },
    setCurrentStudent: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setStudents: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllStudents: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setStudentTeachers: (state, action: PayloadAction<any[]>) => {
      state.teachers = action.payload;
    },
    setStudentReadings: (state, action: PayloadAction<any[]>) => {
      state.readings = action.payload;
    },
    setStudentForegroundLessons: (state, action: PayloadAction<any[]>) => {
      state.lessons = action.payload;
    },
    setStudentForegroundVideo: (state, action: PayloadAction<any>) => {
      state.video = action.payload;
    },
    setStudentForegroundVideoPoster: (state, action: PayloadAction<any>) => {
      state.poster = action.payload;
    },
  },
});
export const {
  setStudent,
  setCurrentStudent,
  setStudents,
  setStudentTeachers,
  setStudentReadings,
  setStudentForegroundLessons,
  setStudentForegroundVideo,
  setStudentForegroundVideoPoster,
  setAllStudents,
} = studentSlice.actions;
export default studentSlice.reducer;
