import React from "react";
import { View, Text, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { isArray, toNumber } from "lodash";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

export declare type tabColum = {
  dataIndex: string;
  title: string;
  children?: tabColum[];
  background?: string;
  color?: string;
  fontFamily?: string;
  border?: string;
};

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "15px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderTop: "1px solid #000",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    paddingTop: "5px",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#fff",
  },
});

export const TableView: React.FC<{
  columns: tabColum[];
  list: any;
  hideHeader?: boolean;
}> = ({ columns, list, hideHeader }) => {
  const [rows, setRows] = React.useState<any[]>([]);
  const [items, setItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    /*const elements: any[] = [];
    columns.forEach((p) => {
      const children = p?.children ?? [];
      if (children.length > 0) {
        children?.forEach((q: any) => {
          elements.push({ title: q.title, dataIndex: q.dataIndex });
        });
      } else {
        elements.push({ title: p.title, dataIndex: p.dataIndex });
      }
    });*/
    setRows(harmonize(columns));
    //if (isArray(list)) {
    setItems(list);
    //}
  }, []);

  function harmonize(list: tabColum[]) {
    let elements: tabColum[] = [];
    list.forEach((p) => {
      if (toNumber(p.children?.length) > 0) {
        const subElements = harmonize(p.children ?? []);
        subElements.forEach((x) => {
          elements.push(x);
        });
      }
      elements.push(p);
    });
    return elements;
  }

  return (
    <>
      {/*hideHeader !== true && (
        <View style={[styles.tableRow]}>
          {columns.map((p, i) => (
            <View key={`tab_${i}`} style={[styles.rowItem, styles.heading]}>
              <Text
                style={{
                  fontFamily: "calibri-bold",
                  fontSize: 11,
                  textAlign: "center",
                  color: "#3e3e3e",
                }}
              >
                {p.title}
              </Text>
              {
               // **@ts-ignore
              p?.children?.length > 0 && (
                <View
                  style={[
                    styles.tableRow,
                    { borderTop: "1px solid #f7f7f7", borderBottom: "none" },
                  ]}
                >
                  //@ts-ignore }
                  {p?.children?.map((x, j) => (
                    <View
                      key={`sub_tab_${j}`}
                      style={[styles.rowItem, styles.heading, { padding: 0 }]}
                    >
                      <Text
                        style={{
                          fontFamily: "calibri-bold",
                          fontSize: 11,
                          textAlign: "center",
                          color: "#3e3e3e",
                        }}
                      >
                        {x.title}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
            </View>
          ))}
        </View>
                      )*/}
      {hideHeader !== true && <HeaderView columns={columns} />}
      {items.map((item, index) => {
        return (
          <ContentView columns={columns} item={item} key={`row_${index}`} />
        );
      })}
    </>
  );
};

const HeaderView: React.FC<{ columns: tabColum[] }> = ({ columns }) => {
  return (
    <View style={[styles.tableRow]}>
      {columns.map((p, i) => (
        <View
          key={`tab_${i}`}
          style={[
            styles.rowItem,
            styles.heading,
            { borderRight: i < columns.length - 1 ? "1px solid #000" : "none" },
          ]}
        >
          <Text
            style={{
              fontFamily: "calibri-bold",
              fontSize: 11,
              textAlign: "center",
              color: "#3e3e3e",
            }}
          >
            {p.title}
          </Text>
          {
            /**@ts-ignore */
            p?.children?.length > 0 && (
              <HeaderView columns={p?.children ?? []} />
            )
          }
        </View>
      ))}
    </View>
  );
};

const ContentView: React.FC<{
  columns: tabColum[];
  item: any;
  child?: boolean;
}> = ({ columns, item, child }) => {
  return (
    <View style={[styles.tableRow, child ? { borderTop: "none" } : {}]}>
      {columns.map((p, i) => {
        const rowStyle = StyleSheet.create({
          text: getRowTextStyle(p.dataIndex, item),
          cellule: getRowStyle(p.dataIndex, item),
        });
        return (
          <View
            key={`row_line_${i}`}
            style={[
              styles.rowItem,
              {
                borderRight: i < columns.length - 1 ? "1px solid #000" : "none",
                paddingTop: 0,
                minHeight: 10,
              },
              rowStyle.cellule,
            ]}
          >
            {
              /**@ts-ignore */
              p?.children?.length > 0 ? (
                <ContentView columns={p?.children ?? []} item={item} child />
              ) : (
                <Text
                  style={[
                    {
                      fontFamily: "calibri",
                      fontSize: 11,
                      textAlign: "center",
                      color: "#3e3e3e",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    rowStyle.text,
                  ]}
                >
                  {getRowTitle(p.dataIndex, item)}
                </Text>
              )
            }
          </View>
        );
      })}
    </View>
  );
};

function getRowTitle(dataIndex: string, item: any) {
  const title = item[dataIndex] ?? "";
  return title;
}

function getRowStyle(dataIndex: string, item: any) {
  const element = item?.style ?? {};
  return element[dataIndex] ?? {};
}

function getRowTextStyle(dataIndex: string, item: any) {
  const element = item?.styleText ?? {};
  return element[dataIndex] ?? {};
}
