import { Button, Card, Col, Layout, notification, Row, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { DlInput, DlPassword } from "../components/input";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";

export function RegisterAccountScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      lastName: "",
      firstName: "",
      password: "",
      confirm: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
      password: yup.string().required("Champ requis"),
      confirm: yup
        .string()
        .required("Champ requis")
        .notOneOf([yup.ref("email")], "Veuillez vérifier votre mot de passe."),
      lastName: yup.string().required("Champ requis"),
      firstName: yup.string().required("Champ requis"),
    }),
    onSubmit: onRegister,
  });

  async function getUser(uid: string) {
    let result = null;
    await userService
      .get(uid)
      .then((response) => {
        result = response.data;
      })
      .catch(() => {});
    return result;
  }

  async function onRegister() {
    const { email, password, firstName, lastName } = formik.values;
    const data = { email, password, firstName, lastName, isAdmin: true };
    setLoading(true);
    await userService
      .store(data)
      .then(() => {
        notification.info({
          duration: 3,
          message: "Connexion au compte",
          description:
            "Un compte administrateur est créé. Veuillez vous connecter",
        });
        window.location.href = "/home/login";
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          description: error,
          message: "Création de compte",
        });
        setLoading(false);
      });
  }

  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ minWidth: 0, padding: 0, background: "#fff" }}
        className="yc-card yc-fade-in"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez créer un compte administrateur`}
        </p>
        <div style={{ padding: "5px 15px 20px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="votre nom"
              type="text"
              formik={formik}
              name="lastName"
              label="Nom"
            />
          </div>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="Votre prénom"
              type="text"
              formik={formik}
              name="firstName"
              label="Prénom"
            />
          </div>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
            />
          </div>
          <div style={{ paddingTop: 5 }}>
            <DlPassword
              label="Mot de passe"
              formik={formik}
              name="password"
              placeholder="Mot de passe ici"
            />
          </div>
          <div style={{ paddingTop: 5 }}>
            <DlPassword
              label="Confirmation de mot de passe"
              formik={formik}
              name="confirm"
              placeholder="Mot de passe ici"
            />
          </div>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button disabled={loading} type="ghost">
                <Link to={"/home/login"}>
                  {`Se connecter à un compte existant`}
                </Link>
              </Button>
            </Col>
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Se connecter`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
