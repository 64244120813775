import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Modal, notification, Row } from "antd";
import { DlInput, DlRadio } from "../../components/input";
import userService from "../../services/userService";

export const CreateStudent: React.FC<
  PropsWithChildren<{ level: string; init: any }>
> = ({ level, init }) => {
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      isStudent: true,
      gender: "",
      birthAt: null,
      establishment: `${establishment.uid}`,
      level: `${level}`,
    },
    onSubmit: onSave,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
    }),
  });

  async function onSave() {
    const data = formik.values;
    setLoading(true);
    await userService
      .store(data)
      .then(async () => {
        if (typeof init === "function") {
          await init();
        }
        notification.info({
          message: "Inscription de l'étudiant",
          description: "Vous avec ajouté un étudiant avec succès",
        });
        setVisible(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur lors de l'Inscription",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  function handleShow() {
    setVisible(true);
  }

  function handleSave() {
    formik.handleSubmit();
  }

  return (
    <React.Fragment>
      <Button type="primary" onClick={handleShow}>
        {`Enregister un étudiant`}
      </Button>
      <Modal
        visible={visible}
        confirmLoading={loading}
        okText="Enregister"
        cancelText="Annuler"
        onCancel={handleHide}
        centered
        onOk={handleSave}
      >
        <div>
          <Row>
            <Col>
              <div style={{ padding: "5px 0" }}>
                <DlInput
                  formik={formik}
                  label="Nom de l'étudiant"
                  name="lastName"
                  placeholder={"Le nom de famille"}
                />
              </div>
            </Col>
            <Col>
              <div style={{ padding: "5px 0" }}>
                <DlInput
                  formik={formik}
                  label="Prénom de l'étudiant"
                  name="firstName"
                  placeholder={"Prénom"}
                />
              </div>
            </Col>
          </Row>
          <div style={{ padding: "5px 0" }}>
            <DlInput
              formik={formik}
              label="Date de naissance"
              name="birthAt"
              type="date"
            />
          </div>
          <div style={{ padding: "5px 0" }}>
            <DlRadio
              formik={formik}
              label="Genre"
              name="gender"
              list={[
                { value: "f", title: "Femme" },
                { value: "m", title: "Homme" },
              ]}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
