import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Button, Col, Row, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";

export function PeriodStudentView() {
  const period = useSelector((state: RootState) => state.period.current);
  const student = useSelector((state: RootState) => state.student.current);
  const level = useSelector((state: RootState) => state.level.current);
  const subjects = useSelector((state: RootState) => state.subject.list);
  const results = useSelector((state: RootState) => state.result.list);
  const [items, setItems] = React.useState<any[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    const elements: any[] = [];
    subjects.map((p: any) => {
      const result = results.find(
        (x) =>
          x?.student?.uid === student?.uid &&
          x?.course?.uid === p.uid &&
          x?.period?.uid === period?.uid
      );
      elements.push({
        ...p,
        result: { point: result?.point ?? 0, max: result?.max ?? 0 },
      });
    });
    setItems(elements);
    console.log("RESULTS", results);
  }, [period, student, subjects, results]);

  function printCard() {
    navigate(`/admin/student/${student.uid}/card`);
  }

  return (
    <React.Fragment>
      <div className="box">
        {results.filter((p) => p?.period?.uid === period.uid).length > 0 && (
          <Row justify="end" style={{ padding: "15px 25px" }}>
            <Col>
              <Button
                onClick={printCard}
                size="large"
                title="Imprimer le bulletin"
                shape="circle"
                type="primary"
              >
                <i className="fa-solid fa-print"></i>
              </Button>
            </Col>
          </Row>
        )}
        <Table
          dataSource={items}
          className="table"
          columns={[
            { title: "Cours", dataIndex: "title" },
            {
              title: `Point obtenu`,
              dataIndex: "result",
              render(value, record, index) {
                let percent = 0;
                if (value.max > 0) {
                  percent = (value.point / value.max) * 100;
                }
                if (value.point === 0 && value.max === 0) {
                  return <Tag>{`-----`}</Tag>;
                } else {
                  return (
                    <Tag
                      color={
                        percent > 50 ? "green" : percent < 50 ? "red" : "orange"
                      }
                    >
                      {`${value.point}/${value.max}`}
                    </Tag>
                  );
                }
              },
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
}
