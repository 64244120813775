import { Button, Table, Tag, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import { upper } from "../../utils/text";
import resultService from "../../services/resultService";
import studentService from "../../services/studentService";
import levelService from "../../services/levelService";
import etsHelper from "../../utils/helpers/etsHelper";
import { toNumber } from "lodash";

interface otherInterface {
  items: any[];
}

const { Text } = Typography;

export const OtherSubjects: React.FC<{ items: any[]; level: any }> = ({
  items,
  level,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [currentPeriod, setCurrentPeriod] = React.useState<any>({});
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const [students, setStudents] = React.useState<any[]>([]);
  const [results, setResults] = React.useState<any[]>([]);

  async function getResults(edition: string, period: string) {
    let result: any[] = [];
    await resultService
      .getByKey(`period=${period}&edition=${edition}&limit=10000`)
      .then((response) => {
        result = resultService.getData(response);
      })
      .catch(() => {});
    return result;
  }

  async function getStudents() {
    let result: any[] = [];
    await studentService
      .getByKey(`level=${level?.uid}&lmit=1000`)
      .then((response) => {
        result = studentService.getData(response);
      })
      .catch(() => {});
    return result;
  }

  async function getLevel() {
    let result: any = {};
    await levelService
      .get(level?.uid)
      .then((response) => {
        result = response.data;
      })
      .catch(() => {});
    return result;
  }

  async function init() {
    setLoading(true);
    const currentLevel = await getLevel();
    const per = await etsHelper.getCurrentPeriod(currentLevel.groups);
    setCurrentPeriod(per);
    const resultList = await getResults(establishment?.edition?.uid, per.uid);
    const studentList = await getStudents();
    students.map((p) => {
      const result = results.find((q) => q.student?.uid === p.uid);
      const point = result?.point ?? 0;
      p.point = point;
    });
    setStudents(studentList);
    setResults(resultList);
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Table
      dataSource={items}
      className="table"
      columns={[
        {
          title: "Titre",
          dataIndex: "title",
          align: "center",
          render(value, record, index) {
            return <div>{upper(value)}</div>;
          },
        },
        {
          title: "Pondération",
          dataIndex: "point",
          align: "center",
          render(value, record, index) {
            return currentPeriod.type === "exam" ? value * 2 : value;
          },
        },
        {
          title: "Enseignant",
          dataIndex: "teachers",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
        {
          title: "Cotation",
          dataIndex: "uid",
          align: "center",
          render(value, record, index) {
            const nbre = results.filter((p) => p.course.uid === value);
            const percent = (nbre.length / students.length) * 100;
            return (
              <Tag
                color={
                  percent === 100 ? "blue" : percent === 0 ? "red" : "green"
                }
              >
                {`${toNumber(percent)} %`}
              </Tag>
            );
          },
        },
        {
          title: "Vérification",
          dataIndex: "forecats",
          align: "center",
          render(value, record, index) {
            return (
              <Text
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
                strong
                type="secondary"
              >
                {value?.length}
              </Text>
            );
          },
        },
        {
          dataIndex: "uid",
          title: "",
          render: (value) => (
            <Button shape="circle" type="primary">
              <Link to={`/admin/subjects/${value}/view`}>
                <i className="fa-duotone fa-arrow-up-right-from-square"></i>
              </Link>
            </Button>
          ),
        },
      ]}
      loading={loading}
    />
  );
};
