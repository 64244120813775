import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface readingInterface {
  "@context": string;
  "@id": string;
  "@type": string;
  student: any;
  lesson: any;
  createdAt: string;
  updatedAt: string;
  type: string;
}
export interface readingData {
  current: readingInterface;
  list: readingInterface[];
  all: readingInterface[];
  childItems: readingInterface[]; //list on dashboard
}

const initialState: readingData = {
  current: {
    "@context": "",
    "@id": "",
    "@type": "",
    createdAt: "",
    updatedAt: "",
    lesson: {},
    student: {},
    type: "",
  },
  list: [],
  all: [],
  childItems: [],
};

export const readingSlice = createSlice({
  name: "reading",
  initialState,
  reducers: {
    setProfilReadings: (state, action: PayloadAction<readingInterface[]>) => {
      state.all = action.payload;
    },
    setReading: (state, action: PayloadAction<readingInterface>) => {
      state.current = action.payload;
    },
    setReadings: (state, action: PayloadAction<readingInterface[]>) => {
      state.list = action.payload;
    },
    setChildReadings: (state, action: PayloadAction<readingInterface[]>) => {
      state.childItems = action.payload;
    },
  },
});
export const { setChildReadings, setProfilReadings, setReading, setReadings } =
  readingSlice.actions;
export default readingSlice.reducer;
