import { Tag } from "antd";
import { isEmpty } from "lodash";
import React from "react";

interface renderGroupInterface {
  group: any;
}

export function RenderGroup(props: renderGroupInterface) {
  const getColor = (type: string): string => {
    switch (type) {
      case "cycle":
        return "cyan";
      case "faculty":
        return "cyan";
      case "section":
        return "orange";
      case "department":
        return "orange";
      case "option":
        return "lime";
      case "option_class":
        return "lime";
      default:
        return "blue";
    }
  };

  const getTitle = (type: string): string => {
    switch (type) {
      case "cycle":
        return "le nom du cycle";
      case "faculty":
        return "le nom de la faculté";
      case "section":
        return "le nom de la section";
      case "department":
        return "le nom de département";
      case "option":
        return "le nom de l'option";
      case "option_class":
        return "le nom de l'option";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: "2px 0" }}>
        <Tag
          title={getTitle(props.group?.type)}
          color={getColor(props.group?.type)}
        >
          {props.group?.name}
        </Tag>
      </div>
      {!isEmpty(props.group?.parent) && (
        <RenderGroup group={props.group?.parent} />
      )}
    </React.Fragment>
  );
}
