import {
  BookFilled,
  BranchesOutlined,
  HomeFilled,
  TeamOutlined,
} from "@ant-design/icons";
import { DashboardScreen } from "./screens/dashboard";
import { EstablishmentScreen } from "./screens/ets";
import { LevelView } from "./screens/level";
import { OrganizationScreen } from "./screens/organization";
import { SchoolScreen } from "./screens/school";
import { StructureView } from "./screens/struct";
import { SubjectScreen } from "./screens/subject";
import { TeacherScreen } from "./screens/teacher";
import { UniversityScreen } from "./screens/university";
import { ForecastView } from "./screens/forecastView";
import { LoginScreen } from "./screens/login";
import { LessonStudioScreen } from "./screens/lessonStudio";
import { RegisterScreen } from "./screens/register";
import { TeacherView } from "./screens/teacher/view";
import { ForecatMonth } from "./screens/forecatMonth";
import { ForecastSubject } from "./screens/subject/forecast";
import { PaymentScreen } from "./screens/subscription/payments";
import { RegisterAccountScreen } from "./screens/registerAccount";
import { EstablishmentConnectScreen } from "./screens/establishmentConnect";
import { SettingScreen } from "./screens/setting";
import { settingRoutes } from "./routes/setting";
import { InfoSettingScreen } from "./screens/settings/info";
import { PeriodScreen } from "./screens/periods";
import { PrinterScreen } from "./screens/settings/print";
import { StudentScreen } from "./screens/students";
import { StudentView } from "./screens/student/view";
import { StudentPrintView } from "./screens/student/print";

export declare type userType = "student" | "responsible" | "teacher";

export interface routeInterface {
  component?: any;
  icon?: any;
  layout: "/admin" | "/home";
  menu: boolean;
  label: string;
  name: string;
  path: string;
  font?: string | null;
  group?: string;
  active?: boolean;
  parent?: string;
}

const routes: routeInterface[] = [
  {
    component: DashboardScreen,
    icon: HomeFilled,
    layout: "/admin",
    menu: true,
    label: "Tableau de bord",
    name: "homeScreen",
    path: "/",
    font: "fa-solid fa-gauge-circle-bolt",
  },
  {
    component: SubjectScreen,
    icon: BookFilled,
    layout: "/admin",
    menu: false,
    label: "cc",
    name: "courseScreen",
    path: "/subjects/:id/view",
    font: null,
  },
  {
    component: LevelView,
    icon: BookFilled,
    layout: "/admin",
    menu: false,
    label: "Classe",
    name: "levelScreen",
    path: "/levels/:id/view",
    font: null,
  },
  {
    component: LessonStudioScreen,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Créer le cours",
    name: "courseScreen",
    path: "/lessons/:id/create",
    font: null,
  },
  {
    font: "fa-light fa-folder-tree",
    label: "Structure",
    name: "structs",
    path: "#",
    layout: "/admin",
    menu: false,
    active: true,
    group: "",
  },
  {
    font: "fa-light fa-folder-tree",
    label: "Elèves",
    name: "students",
    path: "/students",
    layout: "/admin",
    menu: true,
    active: true,
    group: "",
    component: StudentScreen,
  },
  {
    font: "",
    label: "",
    name: "student-view",
    path: "/student/:id",
    layout: "/admin",
    menu: false,
    active: true,
    group: "",
    component: StudentView,
  },
  {
    font: "",
    label: "",
    name: "student-card",
    path: "/student/:id/card",
    layout: "/admin",
    menu: false,
    active: true,
    group: "",
    component: StudentPrintView,
  },
  {
    component: OrganizationScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Structure",
    name: "structureSceen",
    path: "/structure",
    font: "fa-duotone fa-chart-network",
    parent: "",
  },
  {
    component: TeacherScreen,
    icon: HomeFilled,
    layout: "/admin",
    menu: true,
    label: "Enseignant",
    name: "TeacherScreen",
    path: "/teachers",
    font: "fa-duotone fa-chalkboard-user",
  },
  {
    component: EstablishmentScreen,
    icon: BranchesOutlined,
    layout: "/admin",
    menu: false,
    label: "Etablissements",
    name: "agencyScreen",
    path: "/establishments",
    font: "fa-solid fa-school",
  },
  {
    component: LoginScreen,
    icon: null,
    layout: "/home",
    menu: true,
    label: "login",
    name: "loginScreen",
    path: "/login",
    font: null,
  },
  {
    component: EstablishmentConnectScreen,
    icon: null,
    layout: "/home",
    menu: true,
    label: "establishment",
    name: "etsConnectScreen",
    path: "/establishment",
    font: null,
  },
  {
    component: RegisterAccountScreen,
    icon: null,
    layout: "/home",
    menu: true,
    label: "inscription",
    name: "authScreen",
    path: "/register",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Dossiers",
    name: "folderScreen",
    path: "/folder/:uid/view",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Paramètres",
    name: "settingScreen",
    path: "/setting",
    font: null,
  },
  {
    component: null,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "profil",
    name: "profilScreen",
    path: "/profil",
    font: null,
  },

  {
    label: "Configuration",
    layout: "/admin",
    menu: false,
    name: "structConfig",
    path: "/struct/:id",
    active: true,
    component: StructureView,
    font: "fa-solid",
    parent: "structure",
  },
  {
    label: "Classes",
    layout: "/admin",
    menu: true,
    name: "structSchool",
    path: "/struct/school",
    active: true,
    component: SchoolScreen,
    font: "fa-thin fa-school",
    parent: "",
  },
  {
    label: "Université",
    layout: "/admin",
    menu: false,
    name: "structUniv",
    path: "/struct/university",
    active: true,
    component: UniversityScreen,
    font: "fa-thin fa-building-columns",
    parent: "structure",
  },
  {
    label: "previsions",
    layout: "/admin",
    menu: false,
    name: "forecatView",
    path: "/forecasts",
    active: true,
    component: ForecastView,
    font: "fa-duotone fa-books",
  },
  {
    label: "previsions",
    layout: "/admin",
    menu: false,
    name: "forecatMonth",
    path: "/forecast/month",
    active: true,
    component: ForecatMonth,
    font: "fa-duotone fa-books",
  },
  {
    label: "previsions",
    layout: "/admin",
    menu: false,
    name: "forecatSubjectCalendar",
    path: "/forecast/:id/view",
    active: true,
    component: ForecastSubject,
    font: "fa-duotone fa-books",
  },
  {
    label: "Agents",
    layout: "/admin",
    menu: true,
    name: "RegisterScreen",
    path: "/register",
    active: true,
    component: RegisterScreen,
    font: "fa-duotone fa-users",
  },
  {
    label: "teacher",
    layout: "/admin",
    menu: false,
    name: "TeacherView",
    path: "/teacher/:id/view",
    active: true,
    component: TeacherView,
  },
  {
    font: "fa-solid fa-money-bills-simple",
    label: "Paiements",
    name: "payment",
    path: "#",
    layout: "/admin",
    menu: false,
    active: true,
    group: "payment",
  },
  {
    component: OrganizationScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Souscriptions",
    name: "subscriptionSceen",
    path: "/subscriptions",
    font: "fa-solid fa-credit-card",
    parent: "payment",
  },
  {
    component: PaymentScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Caisse",
    name: "paymentScreen",
    path: "/payments",
    font: "fa-solid fa-money-check-dollar-pen",
    parent: "",
  },
  {
    component: SettingScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: true,
    label: "Réglages",
    name: "settingScreen",
    path: "/settings",
    font: "fa-solid fa-screwdriver-wrench",
  },
  {
    component: PeriodScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: false,
    label: "",
    name: "periodScreen",
    path: "/settings/periods",
    font: "fa-solid fa-screwdriver-wrench",
  },
  {
    component: PrinterScreen,
    icon: TeamOutlined,
    layout: "/admin",
    menu: false,
    label: "",
    name: "periodScreen",
    path: "/settings/print",
    font: "fa-solid fa-screwdriver-wrench",
  },
];

settingRoutes.forEach((p) => {
  routes.push(p);
});

export default routes;
