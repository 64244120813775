import React from "react";
import { BreadcrumbInfo } from "../../components/breadcrumb";
import { Bulletin } from "../../components/bulletin";

export function PrinterScreen() {
  return (
    <React.Fragment>
      <div className="box" style={{ background: "gold" }}>
        <BreadcrumbInfo title="Impression de bulletin" />
        <Bulletin />
      </div>
    </React.Fragment>
  );
}
