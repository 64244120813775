import { Avatar, Card, List, Tabs, Typography } from "antd";
import { groupBy, upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import etsHelper from "../../utils/helpers/etsHelper";
import { getUid } from "../../utils/text";
import { CreateGroup } from "../group/create";

interface groupDashboardInterface {
  type?: "default" | "all";
  faculties: any[];
  cycles: any[];
  init?: any;
}

export function GroupDashboard(props: groupDashboardInterface) {
  const { type, cycles, faculties, init } = props;

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Link to={`/admin/struct/${getUid(item["@id"])}`}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Avatar
              size={100}
              style={{ textAlign: "center", background: "#525222" }}
            >
              <i
                className="fa-regular fa-backpack"
                style={{ fontSize: 48 }}
              ></i>
            </Avatar>
            <Card.Meta
              title={upperCase(etsHelper.getGroupName(item.name))}
              style={{ textAlign: "center", padding: 5 }}
            />
          </div>
        </Link>
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      {(cycles.length > 0 || faculties.length > 0) && (
        <div style={{ padding: "0" }}>
          <Typography.Title level={2}>
            {`Structure des établissements`}
          </Typography.Title>
          <Tabs animated defaultActiveKey="0" centered type="card">
            <Tabs.TabPane
              animated
              tab={type === "all" ? "Ecole" : "Cycles"}
              key="0"
              style={{ padding: "10px 30px" }}
            >
              {type === "all" && (
                <div
                  style={{
                    display: "flex",
                    padding: 10,
                    flexDirection: "row-reverse",
                  }}
                >
                  <CreateGroup
                    label="Ajouter un cycle"
                    type="root"
                    onlyValue="cycle"
                    title="Ajouter un cycle"
                    init={init}
                  />
                </div>
              )}
              <div style={{ padding: "50px 0" }}>
                <List
                  grid={{
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                    md: 3,
                    sm: 3,
                    xs: 1,
                    gutter: 4,
                  }}
                  dataSource={cycles}
                  renderItem={renderItem}
                />
              </div>
            </Tabs.TabPane>
            {/*<Tabs.TabPane
              animated
              tab={type === "all" ? "Université" : "Facultés"}
              key="1"
            >
              {type === "all" && (
                <div
                  style={{
                    display: "flex",
                    padding: 10,
                    flexDirection: "row-reverse",
                  }}
                >
                  <CreateGroup
                    label="Ajouter une faculté"
                    type="root"
                    onlyValue="faculty"
                    title="Ajouter une faculté"
                  />
                </div>
              )}
              <List
                grid={{ lg: 4, xl: 4, xxl: 4, md: 4, sm: 3, xs: 1, gutter: 4 }}
                dataSource={faculties}
                renderItem={renderItem}
              />
                </Tabs.TabPane>*/}
          </Tabs>
        </div>
      )}
    </React.Fragment>
  );
}
