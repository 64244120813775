import { Col, DatePicker, Row, Table, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DlInput, DlSelect } from "../../components/input";
import { RootState } from "../../redux/store";
import { useFormik } from "formik";
import subscriptionService from "../../services/subscriptionService";
import {
  setAllUserSubscriptions,
  setLimit,
  setPage,
  setTotal,
} from "../../redux/subscriptionReducer";
import { isEmpty, toNumber, toUpper, upperFirst } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";

export function PaymentScreen() {
  const items = useSelector((state: RootState) => state.subscription.all);
  const [loading, setLoading] = React.useState(true);
  const [init, setInit] = React.useState(false);
  const dispatch = useDispatch();
  const [filterQuery, setFilterQuery] = React.useState<string>("");
  const filter = useSelector((state: RootState) => state.subscription.filter);
  const currentPage = useSelector(
    (state: RootState) => state.subscription.page
  );
  const limit = useSelector((state: RootState) => state.subscription.limit);
  const total = useSelector((state: RootState) => state.subscription.itemCount);
  const [payments] = React.useState<any[]>([
    { value: "", title: "Tous les paiements" },
    { value: "subscription", title: "Abonnement" },
    { value: "payment_1", title: "Payment 1" },
    { value: "payment_2", title: "Payment 2" },
    { value: "payment_3", title: "Payment 3" },
  ]);
  const formik = useFormik({
    initialValues: {
      type: "",
      max: "0",
      min: "0",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    const { max, min, type } = formik.values;
    const elements = filter;
    if (toNumber(max) > 0) {
    } else {
    }
    if (isEmpty(type)) {
    } else {
    }
  }, [formik.values]);

  async function load() {
    await subscriptionService
      .getByKey(filterQuery)
      .then((response) => {
        const data = subscriptionService.getData(response);
        const total = subscriptionService.getTotal(response);
        dispatch(setTotal(total));
        dispatch(setAllUserSubscriptions(data));
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    if (items.length === 0) {
      load();
    } else {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    let query = ``;
    filter.forEach((p: any) => {
      const { key, value } = p;
      if (!isEmpty(key) && !isEmpty(value)) {
        query += `${key}=${value}&`;
      }
    });
    if (limit > 0) {
      query += `limit=${limit}&`;
    }
    if (currentPage > 0) {
      query += `page=${currentPage}`;
    }
    setFilterQuery(query);
  }, [currentPage, limit, filter]);

  React.useEffect(() => {
    if (init) {
      load();
    }
    setInit(true);
  }, [filterQuery]);

  return (
    <div className="box">
      <Typography.Title>{`Les paiements`}</Typography.Title>
      <div>
        <Row gutter={16}>
          <Col>
            <Typography.Text>{`Période`}</Typography.Text>
            <div>
              <DatePicker.RangePicker showTime />
            </div>
          </Col>
          <Col>
            <Typography.Text>{`Type de paiement`}</Typography.Text>
            <DlSelect formik={formik} list={payments} name="type" />
          </Col>
          <Col>
            <DlInput formik={formik} name="min" label={"Montant minimal"} />
          </Col>
          <Col>
            <DlInput formik={formik} name="max" label={"Montant maximal"} />
          </Col>
        </Row>
      </div>
      <div>
        <Table
          dataSource={items}
          loading={loading}
          pagination={
            total > limit
              ? {
                  total,
                  pageSize: limit,
                  current: currentPage,
                  defaultCurrent: 1,
                  defaultPageSize: 10,
                  onChange(page, pageSize) {
                    if (currentPage != page) {
                      dispatch(setPage(page));
                    }
                    if (pageSize != limit) {
                      dispatch(setPage(1));
                      dispatch(setLimit(pageSize));
                    }
                  },
                }
              : undefined
          }
          columns={[
            {
              title: "Nom du client",
              render(value, record, index) {
                return (
                  <div>
                    {`${upperFirst(value?.user?.firstName)} ${toUpper(
                      value?.user?.lastName
                    )}`}
                  </div>
                );
              },
              align: "center",
            },
            {
              title: "Montant payé",
              render(value) {
                return <div>{`${value?.amount} ${value?.currency ?? ""}`}</div>;
              },
              align: "center",
            },
            {
              title: "Date de paiement",
              render(value) {
                return (
                  <div>
                    {`${dateHelpers.getDate(
                      value?.createdAt,
                      "DD MMM YYYY HH:mm"
                    )}`}
                  </div>
                );
              },
              align: "center",
            },
          ]}
        />
      </div>
    </div>
  );
}
