import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, List, Row, Typography } from "antd";
import { upperCase, upperFirst } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CreateStudent } from "../student/create";

export const LevelStudentsView: React.FC<{ level: any; init: any }> = ({
  level,
  init,
}) => {
  const students = useSelector((state: RootState) => state.level.students);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Row align="middle" gutter={8}>
          <Col>
            <Avatar size="large">
              <UserOutlined />
            </Avatar>
          </Col>
          <Col>
            <Typography.Text strong>
              {`${upperFirst(item?.users?.firstName)} ${upperCase(
                item?.users?.lastName
              )}`}
            </Typography.Text>
          </Col>
          <Col></Col>
        </Row>
      </List.Item>
    );
  };
  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <CreateStudent level={level?.uid} init={init} />
        </Col>
      </Row>
      <List dataSource={students} renderItem={renderItem} />
    </React.Fragment>
  );
};
