import { Breadcrumb, Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const BreadcrumbInfo: React.FC<{ title: string }> = ({ title }) => {
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={"/admin/"}>Tableau de bord</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/admin/settings"}>Réglages</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
    </React.Fragment>
  );
};
