import React from "react";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface contentInterface {
  description: any;
  setDescription: any;
}
export function ContentLesson(props: contentInterface) {
  const { description, setDescription } = props;

  const [userInfo, setuserInfo] = React.useState<any>({
    title: "",
    description: "",
  });
  const onChangeValue = (e: any) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onEditorStateChange = (editorState: any) => {
    if (typeof setDescription === "function") {
      setDescription(editorState);
    }
  };

  const toolbar = {
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      icon: "fontSize",
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  };

  return (
    <React.Fragment>
      <div className="form-group col-md-12 editor">
        <label className="font-weight-bold">
          {" "}
          Description <span className="required"> * </span>{" "}
        </label>
        <Editor
          editorState={description}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          editorStyle={{
            background: "#f1f1f1",
            minHeight: 350,
            maxHeight: 380,
            padding: 10,
            margin: "0 0 10px",
          }}
          locale="fr"
        />
        <textarea
          style={{ display: "none" }}
          disabled
          ref={(val) => (userInfo.description = val)}
          value={draftToHtml(convertToRaw(description.getCurrentContent()))}
        />
      </div>
    </React.Fragment>
  );
}
