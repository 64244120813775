import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface levelData {
  current: any;
  list: any[];
  teachers: any[];
  students: any[];
  filters: any[];
}

const initialState: levelData = {
  current: {},
  list: [],
  teachers: [],
  students: [],
  filters: [],
};

export const universitySlice = createSlice({
  name: "university",
  initialState,
  reducers: {
    setCurrentClass: (state, action: PayloadAction<object>) => {
      state.current = action.payload;
    },
    setUniversityLevels: (state, action: PayloadAction<object[]>) => {
      state.list = action.payload;
    },
    setUniversityStudents: (state, action: PayloadAction<any[]>) => {
      state.students = action.payload;
    },
    setUniversityTeachers: (state, action: PayloadAction<any[]>) => {
      state.teachers = action.payload;
    },
    setUniversityFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
  },
});
export const {
  setCurrentClass,
  setUniversityLevels,
  setUniversityStudents,
  setUniversityTeachers,
  setUniversityFilters,
} = universitySlice.actions;
export default universitySlice.reducer;
