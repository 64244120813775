import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Col, Row } from "antd";
import { DlSelect } from "../../components/input";
import { useFormik } from "formik";
import { ChartField } from "./chart";
import { isEmpty, toNumber } from "lodash";

export function StatStudentView() {
  const period = useSelector((state: RootState) => state.period.current);
  const periods = useSelector((state: RootState) => state.period.list);
  const student = useSelector((state: RootState) => state.student.current);
  const level = useSelector((state: RootState) => state.level.current);
  const subjects = useSelector((state: RootState) => state.subject.list);
  const results = useSelector((state: RootState) => state.result.list);
  const [data, setData] = React.useState<any>({});
  const [courses, setCourses] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      course: "",
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const elements: any[] = [];
    const labels: any[] = [];
    const uid = formik.values.course;
    periods.forEach((p) => {
      labels.push(p.title);
      const result = results.find(
        (x) =>
          x?.student?.uid === student?.uid &&
          x?.course?.uid === uid &&
          x?.period?.uid === p?.uid
      );
      const point = toNumber(result?.point);
      const max = toNumber(result?.max);
      let percent = 0;
      if (max > 0) {
        percent = (point / max) * 100;
      }
      elements.push(percent);
    });
    setData({
      labels,
      datasets: [
        {
          label: "Ratio de resultat",
          data: elements,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
  }, [formik.values.course]);

  function init() {
    const elements: any[] = [];
    subjects.forEach((p) => {
      elements.push({ value: `${p.uid}`, title: p.title });
    });
    elements.unshift({ value: "", title: "Choisir un cours" });
    setCourses(elements);
    if (elements.length > 1) {
      formik.setValues({ course: elements[1].value });
    }
  }

  return (
    <React.Fragment>
      <section className="box">
        <Row>
          <Col span={8}>
            <DlSelect list={courses} formik={formik} name="course" />
          </Col>
        </Row>
        {!isEmpty(data) && <ChartField data={data} title={`cours`} />}
      </section>
    </React.Fragment>
  );
}
