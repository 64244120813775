import { Avatar, Card, Row, Tag, Typography } from "antd";
import { upperCase } from "lodash";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export const MonthCard: React.FC<
  PropsWithChildren<{
    index: number;
    title: string;
    year: number;
    count: number;
    link?: boolean;
  }>
> = ({ count, index, title, year, link }) => {
  return (
    <React.Fragment>
      {/**@ts-ignore */}
      <Card className="card" style={{ width: 220, padding: 10 }}>
        <Row align="middle" justify="center">
          <div style={{ textAlign: "center" }}>
            <div>
              <Typography.Text strong>{upperCase(title)}</Typography.Text>
            </div>
            <Avatar style={{ margin: "10px 0", backgroundColor: "teal" }}>
              {index}
            </Avatar>
            <div>
              <Tag color={count > 0 ? "green" : "red"}>
                {count > 0 ? `${count}` : "Aucune"}
              </Tag>
              <span
                style={{ fontStyle: "italic", fontWeight: "500" }}
              >{`prévision${count > 1 ? "s" : ""}`}</span>
            </div>
          </div>
        </Row>
      </Card>
    </React.Fragment>
  );
};
