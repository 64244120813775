import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Image,
  Row,
  Typography,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ItemValue } from "../../components/item";
import { RootState } from "../../redux/store";
import { BreadcrumbInfo } from "../../components/breadcrumb";

export function InfoSettingScreen() {
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );

  return (
    <div className="box" style={{ background: "gold" }}>
      <BreadcrumbInfo title="Informations de l'école" />
      <div>
        <Row gutter={4}>
          <Col lg={8}>
            <Card className="card__simple">
              <div className="bg__poster">
                <Image
                  src={require("../../assets/images/student.jpg")}
                  preview={false}
                />
                <div className="avatar__poster">
                  <Avatar></Avatar>
                </div>
              </div>
              <div className="next">
                <Typography.Title level={2} className="text__edition">
                  {`2019-2020`}
                  <Button>
                    <i className="fa-solid fa-edit"></i>
                  </Button>
                </Typography.Title>
                <Typography.Title level={2} className="text__subtitle">
                  {establishment.name}
                </Typography.Title>
              </div>
              <div>
                <span className="text__center text__icon">
                  <i className="fa-thin fa-lock"></i>
                  <span>{`280 élèves archivés`}</span>
                </span>
                <div>{/**Afficher quelques élévès en group avatar */}</div>
              </div>
            </Card>
          </Col>
          <Col flex={1}>
            <Card className="card">
              <Card.Meta title="Déscription" description="notre école" />
            </Card>
            <Card className="card">
              <ItemValue title="Nom de l'école" value={establishment.name} />
              <ItemValue title="Nombre de classe" value={"2"} />
              <ItemValue title="Nombre d'étudiants" value={"20"} />
            </Card>
            {/**carte si long et lat existent */}
          </Col>
        </Row>
      </div>
    </div>
  );
}
