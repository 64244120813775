import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface groupData {
  current: any;
  list: any[];
  all: any[];
  filters: any[];
  itemLength: number;
}

const initialState: groupData = {
  current: {},
  list: [],
  filters: [],
  itemLength: 0,
  all: [],
};

export const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setGroups: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllGroups: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setGroupFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
    },
    setGroupCount: (state, action: PayloadAction<number>) => {
      state.itemLength = action.payload;
    },
  },
});
export const {
  setAllGroups,
  setGroup,
  setGroupCount,
  setGroupFilters,
  setGroups,
} = groupSlice.actions;
export default groupSlice.reducer;
