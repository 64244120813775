import { Button, Card, Col, Layout, notification, Row, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DlInput, DlPassword } from "../components/input";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";

export function LoginScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez écrire votre email correctement"),
      password: yup.string().required("Champ requis"),
    }),
    onSubmit: onLogin,
  });

  async function getUser(uid: string) {
    let result = null;
    await userService
      .get(uid)
      .then((response) => {
        result = response.data;
      })
      .catch(() => {});
    return result;
  }

  async function onLogin() {
    const { email, password } = formik.values;
    setLoading(true);
    await userService
      .login({ username: email, password })
      .then(async (response) => {
        const data = response.data;
        const uid = data?.user;
        const element = await getUser(uid ?? "");
        if (!isEmpty(element)) {
          if (!userHelper.isAdmin(element)) {
            notification.warning({
              duration: 3,
              message: "Connexion au compte",
              description:
                "Vous n'êtes pas un administrateur: veuillez contacter la hierarchie.",
            });
            setLoading(false);
            return;
          }
          userHelper.setToken(data.token);
          userHelper.setData(element ?? {}, dispatch);
          //window.location.href = "/home/establishment";
          navigate("/home/establishment", { replace: true });
        } else {
          notification.warning({
            message: "Connexion",
            description: "Veuillez vérifier vos identifiants",
          });
          setLoading(false);
        }
      })
      .catch((reason) => {
        const errorText = "Adresse E-mail ou mot de passe incorrect";
        formik.setErrors({
          email: errorText,
          password: errorText,
        });
        setLoading(false);
      });
  }

  return (
    <Layout
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{ minWidth: 500, padding: 0, background: "#fff" }}
        className="yc-card"
      >
        <div
          style={{ width: "100%", textAlign: "center", padding: "10px 0 5px" }}
        >
          <img
            src={require("../assets/images/logo.png")}
            style={{ textAlign: "center", width: 100 }}
          />
        </div>
        <p style={{ textAlign: "center" }}>
          {`Veuillez vous connecter à votre compte ci-dessous.`}
        </p>
        <div style={{ padding: "5px 15px 20px" }}>
          <div style={{ paddingTop: 5 }}>
            <DlInput
              placeholder="your@domain.com"
              type="email"
              formik={formik}
              name="email"
              label="E-mail"
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            <DlPassword
              label="Mot de passe"
              formik={formik}
              name="password"
              placeholder="Mot de passe ici"
            />
          </div>
        </div>
        <Row
          style={{
            background: "#f1f1f1",
            padding: "15px 10px",
            borderBottomLeftRadius: 10,
            WebkitBorderBottomRightRadius: 10,
          }}
          justify="end"
        >
          <Row gutter={4} align="middle">
            <Col>
              <Button disabled={loading} type="ghost">
                <Link to={"/home/register"}>{`S'inscrire`}</Link>
              </Button>
            </Col>
            <Col>
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => formik.handleSubmit()}
                type="primary"
              >
                {`Se connecter`}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Layout>
  );
}
