import { Button, List } from "antd";
import React from "react";

interface levelListInterface {
  uid?: string;
  list: any[];
  setList?: any;
}

export function LevelList(props: levelListInterface) {
  const { list, setList } = props;

  function handleRemove(item: any) {
    if (typeof setList === "function") {
      const elements = list.filter((p) => p.uid != item.uid);
      setList(elements);
    }
  }

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Button
          onClick={() => handleRemove(item)}
          danger
          style={{ width: "100%" }}
        >
          <span>{item.title}</span>
          <span style={{ paddingRight: 5, fontWeight: "bold" }}>
            {`: ${item?.groups?.name}`}
          </span>
        </Button>
      </List.Item>
    );
  };
  return (
    <div>
      <List dataSource={list} renderItem={renderItem} />
    </div>
  );
}
