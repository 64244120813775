import { FilePdfFilled, PlusOutlined } from "@ant-design/icons";
import { Modal, Typography, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import React, { useState } from "react";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface docInterface {
  fileList: UploadFile[];
  setFileList: any;
}
const DocLesson = (props: docInterface) => {
  const { fileList, setFileList } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        action={undefined}
        listType="picture-card"
        accept="application/pdf"
        showUploadList
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        itemRender={(
          originNode: React.ReactElement<
            any,
            string | React.JSXElementConstructor<any>
          >,
          file: UploadFile<any>,
          fileList: UploadFile<any>[],
          actions: {
            download: () => void;
            preview: () => void;
            remove: () => void;
          }
        ) => {
          return (
            <div style={{ textAlign: "center" }}>
              <div style={{ position: "relative" }}>
                <FilePdfFilled style={{ fontSize: 110 }} />
              </div>
              <Typography.Paragraph
                title={file.name}
                style={{ textAlign: "center", padding: "1px 2px" }}
                ellipsis={{ rows: 1 }}
              >
                {file.name}
              </Typography.Paragraph>
            </div>
          );
        }}
        isImageUrl={(file: UploadFile<any>) => {
          return false;
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <iframe style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export { DocLesson };
