import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Avatar, Button, Table } from "antd";
import userHelper from "../utils/helpers/userHelper";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

export function StudentScreen() {
  const students = useSelector((state: RootState) => state.student.all);
  const [items, setItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    setItems(students);
  }, [students]);
  return (
    <React.Fragment>
      <section className="box">
        <Table
          dataSource={items}
          className="table"
          columns={[
            {
              title: "",
              render(value, record, index) {
                return (
                  <Avatar
                    src={!isEmpty(value.photo) ? value.photo : ""}
                    size="large"
                  >
                    {isEmpty(value.photo) && (
                      <i className="fa-solid fa-user"></i>
                    )}
                  </Avatar>
                );
              },
              width: 65,
            },
            {
              title: "Nom de l'élève",
              dataIndex: "users",
              render(value, record, index) {
                return userHelper.getUserName(value);
              },
            },
            {
              title: "Classe",
              dataIndex: "level",
              render(value, record, index) {
                return `${value?.title} ${value?.groups?.name ?? ""} ${
                  value?.reference
                }`;
              },
            },
            {
              dataIndex: "uid",
              title: "",
              render: (value) => (
                <Button shape="circle" type="primary">
                  <Link to={`/admin/student/${value}`}>
                    <i className="fa-duotone fa-arrow-up-right-from-square"></i>
                  </Link>
                </Button>
              ),
            },
          ]}
        />
      </section>
    </React.Fragment>
  );
}
