import React from "react";
import {
  Document,
  StyleSheet,
  Page,
  View,
  Text,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { HeaderBulletin } from "./pdf/header";
import { IdentityBulletin } from "./pdf/identity";
import { TableView, tabColum } from "./pdf/table";
import points from "./pdf/list.json";

export const Bulletin: React.FC<{}> = ({}) => {
  const styles = StyleSheet.create({
    page: {
      padding: "1cm 1cm",
      backgroundColor: "#fff",
    },
    container: {
      border: "1px solid #000",
    },
  });

  const [columns] = React.useState<tabColum[]>([
    { dataIndex: "branch", title: "Branches" },
    {
      dataIndex: "",
      title: "PREMIER SEMESTRE",
      children: [
        {
          dataIndex: "trav",
          title: "Trav. j",
          children: [
            { dataIndex: "p1", title: "1eP" },
            { dataIndex: "p2", title: "2eP" },
          ],
        },
        { dataIndex: "comp1", title: "COMP" },
        { dataIndex: "tot1", title: "TOT" },
      ],
    },
    {
      dataIndex: "",
      title: "SECOND SEMESTRE",
      children: [
        {
          dataIndex: "trav",
          title: "Trav. j",
          children: [
            { dataIndex: "p3", title: "3eP" },
            { dataIndex: "p4", title: "4eP" },
          ],
        },
        { dataIndex: "comp2", title: "COMP" },
        { dataIndex: "tot2", title: "TOT" },
      ],
    },
    { dataIndex: "total", title: "TOT GEN" },
  ]);

  return (
    <React.Fragment>
      <section>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="MAKTAB" creator="DEV">
            <Page size="A4" style={[styles.page]}>
              <View style={styles.container}>
                <HeaderBulletin photo="cj" />
                <IdentityBulletin
                  birthDate="Kinshasa, le 22 mai 2001"
                  bornPlace=""
                  classe="d"
                  commune="ngaliema"
                  fullName="d"
                  province="kxkxd"
                  school=""
                  sex=""
                />
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}>
                    <TableView list={points} columns={columns} />
                  </View>
                  <View></View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
};
