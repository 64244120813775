import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DlInput, DlSelect } from "../../components/input";
import establishmentService from "../../services/establishmentService";

interface createEtsInterface {
  reloading?: any;
}

export function CreateEts(props: createEtsInterface) {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      type: "school",
      name: "",
    },
    onSubmit: handleSave,
    validationSchema: yup.object({
      type: yup.string().required("Champ requis"),
      name: yup.string().required("Champ requis"),
    }),
  });

  async function handleSave() {
    const data = formik.values;
    setLoading(true);
    await establishmentService
      .store(data)
      .then(() => {
        notification.info({
          message: "Enregistrement",
          description: "Correct",
        });
        if (typeof props.reloading === "function") {
          props.reloading();
        }
        setVisible(false);
        formik.resetForm();
      })
      .catch((reason) => {
        console.log("R", reason);
        notification.error({
          message: "Enregistrement de l'établissement",
          description: "Echec",
        });
      });
    setLoading(false);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    setVisible(false);
  }

  return (
    <React.Fragment>
      <Button onClick={() => setVisible(true)} type="primary">
        {`Ajouter un etablissement`}
      </Button>
      <Modal
        visible={visible}
        title="Ajoutez une école ou université"
        confirmLoading={loading}
        okText="Ajouter"
        cancelText="Annuler"
        onCancel={handleHide}
        onOk={() => formik.handleSubmit()}
      >
        <DlSelect
          formik={formik}
          name="type"
          list={[
            { value: "school", title: "Ecole" },
            { value: "university", title: "Université ou institut" },
          ]}
        />
        <DlInput
          formik={formik}
          name="name"
          label={`Le nom de ${
            formik.values.type === "school"
              ? "l'école"
              : "l'université ou institut"
          }`}
        />
      </Modal>
    </React.Fragment>
  );
}
