import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import { DlInput } from "../../components/input";
import { isEmpty, toString } from "lodash";
import levelService from "../../services/levelService";
import { getUid } from "../../utils/text";
import { setLevel } from "../../redux/levelReducer";

export function UpdateTitleLevel() {
  const level = useSelector((state: RootState) => state.level.current);
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: { title: level.title },
    onSubmit: handleUpdate,
    validationSchema: yup.object({
      title: yup.string().required("Champ requis"),
    }),
  });

  async function handleUpdate() {
    const data = formik.values;
    setLoading(true);
    await levelService
      .update(toString(getUid(level["@id"])), data)
      .then((response) => {
        dispatch(setLevel(response.data));
        setLoading(false);
        setVisible(false);
        formik.handleReset(() => {});
        notification.info({
          message: "Modification de titre",
          description: "Le titre est modifié avec succès",
        });
      })
      .catch((reason) => {
        notification.error({
          message: "Modification de titre",
          description: "La modification a échoué",
        });
      });
    setLoading(false);
  }
  function handleClose() {
    if (loading) {
      return;
    }
    setVisible(false);
    formik.handleReset(() => {});
  }
  function handleOpen() {
    if (isEmpty(level["@id"])) {
      notification.warning({
        message: "Lancement de la modification",
        description: "Impossible d'ouvrir la boîte de modification",
      });
      return;
    }
    setVisible(true);
  }

  return (
    <React.Fragment>
      <Button type="primary" shape="round" onClick={handleOpen}>
        Modifier le titre
      </Button>
      <Modal
        visible={visible}
        onOk={() => formik.handleSubmit()}
        onCancel={handleClose}
        closable={!loading}
        okText="Modifier"
        cancelText="Annuler"
        confirmLoading={loading}
      >
        <div>
          <DlInput
            label="Titre de la promotion"
            name="title"
            placeholder="Le titre de la promotion ici"
            formik={formik}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
