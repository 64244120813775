import { Card, Carousel, Col, Layout, Row, Steps, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RenderGroup } from "../../components/renderGroup";
import { RenderGroupStep } from "../../components/renderGroupStep";
import { RootState } from "../../redux/store";
import etsHelper from "../../utils/helpers/etsHelper";
import { toUpper } from "lodash";

export function ShowLevel() {
  const level = useSelector((state: RootState) => state.level.current);
  const [period, setPeriod] = React.useState("");

  React.useEffect(() => {
    init();
  }, [level]);

  async function init() {
    const per = await etsHelper.getCurrentPeriod(level.groups);
    setPeriod(per.title);
  }

  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        <Typography.Title
          level={2}
          className="yc_text_primary"
          style={{ textAlign: "center" }}
        >
          {`${level?.title} ${level?.reference}`}
        </Typography.Title>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Cycle, section, option" className="card">
              <RenderGroupStep group={level?.groups} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Période en cours" className="card">
              <Typography.Text strong type="success">
                {toUpper(period)}
              </Typography.Text>
            </Card>
          </Col>

          <Col span={8}>
            <Card title="Année scolaire" className="card">
              <Typography.Text strong>{`2022-2023`}</Typography.Text>
            </Card>
          </Col>
          {/*<Col span={8}>
            <Card title="Les cours" className="card">
              <RenderGroupStep group={level?.groups} />
            </Card>
          </Col>
          <Col span={16}>
            <Card title="Les cours et stats en carousel" className="card">
              <RenderGroupStep group={level?.groups} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Eleves" className="card">
              <RenderGroupStep group={level?.groups} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Enseignants" className="card">
              <RenderGroupStep group={level?.groups} />
            </Card>
          </Col>
          <Col span={18}>
            <Carousel></Carousel>
  </Col>*/}
        </Row>
      </Layout>
    </React.Fragment>
  );
}
