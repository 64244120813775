import React from "react";
import { Typography } from "antd";

const { Text, Title } = Typography;

function ActualityComponent() {
  return (
    <div className="card" style={{ padding: 5, marginTop: 40 }}>
      <Title level={3} style={{ textAlign: "center" }}>
        Calendrier
      </Title>
    </div>
  );
}

export { ActualityComponent };
