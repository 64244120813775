import { Button, Modal, notification, Steps, Typography } from "antd";
import { useFormik } from "formik";
import { isEmpty, toNumber, toUpper } from "lodash";
import React from "react";
import * as yup from "yup";
import { DlInput, DlSelect, DlTextarea } from "../../components/input";
import forecastService from "../../services/forecastService";

interface createForecastInterface {
  init?: any;
  subject?: any;
  item: any;
}

interface cmpInterface {
  domaine?: string;
  formik: any;
}

const { Text } = Typography;

function CreateStep1(props: cmpInterface) {
  return (
    <div>
      <div>
        <Text>
          Domaine: <Text strong>{props.domaine}</Text>
        </Text>
      </div>
      <DlInput
        formik={props.formik}
        label="Titre de la leçon"
        name="title"
        placeholder="Titre de la leçon"
      />
      <DlSelect formik={props.formik} name="branch" list={[]} label="Branche" />
      <DlTextarea
        formik={props.formik}
        label="Objectif de la prevision"
        name="purpose"
        placeholder="Dites ce que"
      />
    </div>
  );
}

function CreateStep2(props: cmpInterface) {
  return (
    <div>
      <div>
        <Text>
          Domaine: <Text strong>{props.domaine}</Text>
        </Text>
      </div>
      <DlTextarea
        formik={props.formik}
        label="Resumé de la leçon"
        name="summary"
        placeholder="Dites de façon brève, le contenu de la leçon"
      />
      <DlTextarea
        formik={props.formik}
        label="Activités des parents"
        name="activities"
        placeholder="Dites ce que"
      />
      <DlTextarea
        formik={props.formik}
        label="Culture générale"
        name="generalCulture"
        placeholder="Dites ce que"
      />
    </div>
  );
}

export function CreateForecast(props: createForecastInterface) {
  const { init, subject, item } = props;
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      branch: null,
      period: null,
      subject: subject,
      title: "",
      purpose: "",
      week: item.week,
      month: item.month,
      year: item.year,
    },
    validationSchema: yup.object({
      purpose: yup.string().required("Champ requis"),
      title: yup.string().required("Champ requis"),
    }),
    onSubmit: handleStep1,
  });

  React.useEffect(() => {
    formik.setValues({
      ...formik.values,
      week: item.week,
      month: item.month,
      year: item.year,
    });
  }, [item]);

  const formik2 = useFormik({
    initialValues: {
      summary: "",
      activities: "",
      generalCulture: "",
    },
    validationSchema: yup.object({
      summary: yup.string().required("Champ requis"),
    }),
    onSubmit: handleStep2,
  });

  function handleStep1() {
    setStep(1);
  }

  async function handleStep2() {
    const { branch, period, purpose, subject, month, week, year, title } =
      Object.assign(formik.values);
    const { activities, generalCulture, summary } = Object.assign(
      formik2.values
    );

    const data: any = {
      branch,
      period,
      purpose,
      subject: `/api/subjects/${subject?.uid ?? subject}`,
      activities,
      generalCulture,
      summary,
      month: toNumber(month),
      week,
      year: toNumber(year),
      title,
    };
    for (const key in data) {
      if (null === data[key]) {
        delete data[key];
      }
    }
    setLoading(true);
    await forecastService
      .store(data)
      .then(async (response) => {
        notification.info({
          message: "Création de prévision",
          description: "La création a réussi",
        });
        if (typeof props.init === "function") {
          await props.init();
        }
        if (typeof setVisible === "function") {
          setVisible(false);
        }
        setStep(0);
        formik.resetForm();
        formik2.resetForm();
        setVisible(false);
      })
      .catch((reason) => {
        notification.error({
          message: "Création de prévision",
          description: "La prévison a échoué",
        });
      });
    setLoading(false);
  }

  function handleHide() {
    if (loading) {
      return;
    }
    if (step === 0) {
      if (typeof setVisible === "function") {
        setVisible(false);
      }
    } else {
      setStep(step - 1);
    }
  }

  function handleClick() {
    if (step === 0) {
      formik.handleSubmit();
    } else if (step === 1) {
      formik2.handleSubmit();
    }
  }

  function handleShow() {
    setVisible(true);
  }

  return (
    <React.Fragment>
      <Button onClick={handleShow} type="primary">
        {`Ajouter une prévision`}
      </Button>
      <Modal
        title={`Ajouter une prevision pour ${toUpper(subject.title)}`}
        visible={visible}
        okText={`${step == 0 ? "Suivant" : "Terminer"}`}
        onOk={handleClick}
        cancelText={`${step == 0 ? "Annuler" : "Retourner"}`}
        onCancel={handleHide}
        confirmLoading={loading}
      >
        <div>
          <div>
            {`Semaine de l'année: ${props.item.week}`}
            <span style={{ padding: "0 10px" }}>
              {`Semaine du mois : `}
              <Text strong>{props.item.monthWeek}</Text>
            </span>
          </div>
          <div style={{ width: 300, margin: "auto", padding: 10 }}>
            <Steps current={step}>
              <Steps.Step />
              <Steps.Step />
            </Steps>
          </div>
          {step === 0 && (
            <CreateStep1 formik={formik} domaine={subject.title} />
          )}
          {step === 1 && (
            <CreateStep2 formik={formik2} domaine={subject.title} />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}
