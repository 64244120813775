import React, { useContext, useEffect, useRef, useState } from "react";
import { InputRef, notification } from "antd";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import etsHelper from "../../utils/helpers/etsHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import levelService from "../../services/levelService";
import { setLevel } from "../../redux/levelReducer";
import studentService from "../../services/studentService";
import resultService from "../../services/resultService";
import userHelper from "../../utils/helpers/userHelper";
import { toNumber } from "lodash";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  point?: string;
  uid?: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const RatingSubject: React.FC<{ subject: any }> = ({ subject }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const level = useSelector((state: RootState) => state.level.current);
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const [results, setResults] = React.useState<any[]>([]);
  const [currentPeriod, setCurrentPeriod] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {}, [dataSource]);

  React.useEffect(() => {
    init();
  }, [subject]);

  async function getLevel() {
    let result: any = {};
    await levelService
      .get(subject?.level?.uid)
      .then((response) => {
        result = response.data;
        dispatch(setLevel(result));
      })
      .catch(() => {});
    return result;
  }

  async function getStudents() {
    let result: any[] = [];
    await studentService
      .getByKey(`level=${subject?.level?.uid}&limit=1000`)
      .then((response) => {
        result = studentService.getData(response);
      })
      .catch(() => {});
    return result;
  }

  async function getResults(edition: string, period: string) {
    let result: any[] = [];
    await resultService
      .getByKey(
        `course=${subject?.uid}&period=${period}&edition=${edition}&limit=1000`
      )
      .then((response) => {
        result = resultService.getData(response);
      })
      .catch(() => {});
    return result;
  }

  async function init() {
    setLoading(true);
    const currentLevel = await getLevel();
    const per = await etsHelper.getCurrentPeriod(currentLevel.groups);
    setCurrentPeriod(per);
    const results = await getResults(establishment?.edition?.uid, per.uid);
    const students = await getStudents();
    students.map((p) => {
      const result = results.find((q) => q.student?.uid === p.uid);
      const point = result?.point ?? 0;
      p.point = point;
    });
    setDataSource(students);
    setLoading(false);
  }

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Nom de l'élève",
      dataIndex: "users",
      width: "30%",
      editable: false,
      render(value, record, index) {
        return userHelper.getUserName(value);
      },
    },
    {
      title: "point",
      dataIndex: "point",
      editable: true,
    },
    {
      title: "Point de cotation",
      dataIndex: "max",
      render(value, record, index) {
        return currentPeriod.type === "exam"
          ? subject.point * 2
          : subject.point;
      },
    },
  ];

  const handleSave = async (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    const data: any = {
      point: toNumber(row.point),
      max: 0,
      edition: `/api/editions/${establishment?.edition?.uid}`,
      course: `/api/courses/${subject.uid}`,
      student: `/api/students/${row.uid}`,
      period: `/api/periods/${currentPeriod.uid}`,
    };
    const max =
      currentPeriod.type === "exam" ? subject.point * 2 : subject.point;
    if (toNumber(row.point) > max) {
      notification.warning({
        message: "Attention sur le point de cotation",
        description: `${row.point}/${max} n'est pas possible`,
      });
      return;
    }
    resultService
      .store(data)
      .then((response) => {
        console.log("REP", response);
      })
      .catch((reason) => {
        //const error = resultService.getError(reason);
        console.log("ERROR", reason);
      });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        className="table"
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        loading={loading}
      />
    </div>
  );
};
