import { routeInterface } from "../routes";
import { InfoSettingScreen } from "../screens/settings/info";
const routes: routeInterface[] = [
  {
    component: InfoSettingScreen,
    icon: null,
    layout: "/admin",
    menu: false,
    label: "Réglages",
    name: "settingScreen",
    path: "/settings/info",
    font: "fa-solid fa-screwdriver-wrench",
  },
];

export { routes as settingRoutes };
