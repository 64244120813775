import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  profil: object;
  current: object;
  list: object[];
}

const initialState: userData = {
  profil: {},
  current: {},
  list: [],
};

export const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    setProfil: (state, action: PayloadAction<object>) => {
      state.profil = action.payload;
    },
    setCurrent: (state, action: PayloadAction<object>) => {
      state.current = action.payload;
    },
    setList: (state, action: PayloadAction<object[]>) => {
      state.list = action.payload;
    },
  },
});
export const { setProfil, setCurrent, setList } = lessonSlice.actions;
export default lessonSlice.reducer;
