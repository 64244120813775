import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import groupService from "../services/groupService";
import { BreadcrumbInfo } from "../components/breadcrumb";
import { toUpper } from "lodash";
import { PeriodView } from "./period/listByCycle";

export function PeriodScreen() {
  //const groups = useSelector((state:RootState)=>state.)
  const establishment = useSelector(
    (state: RootState) => state.establishment.profil
  );
  const [cycles, setCycles] = React.useState<any[]>([]);

  async function init() {
    groupService
      .getByKey(`type=cycle&establishment=${establishment.uid}`)
      .then((response) => {
        const data = groupService.getData(response);
        setCycles(data);
      });
  }

  React.useEffect(() => {
    init();
  }, []);

  return (
    <React.Fragment>
      <div className="box" style={{ background: "gold" }}>
        <BreadcrumbInfo title="Géstionnaire des périodes" />
        <Tabs>
          {cycles.map((p, i) => (
            <Tabs.TabPane
              tab={`${toUpper(p.name)}`}
              key={`${p.uid}`}
              tabKey={`${p.uid}`}
            >
              <PeriodView key={`period_${i}_${p.uid}`} cycleUid={p.uid} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </React.Fragment>
  );
}
