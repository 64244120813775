import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface branchData {
  current: any;
  list: any[];
}

const initialState: branchData = {
  current: {},
  list: [],
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setBranch: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setBranchs: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
  },
});
export const { setBranch, setBranchs } = branchSlice.actions;
export default branchSlice.reducer;
