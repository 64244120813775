import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helpers/userHelper";
import { toUpper, upperFirst } from "lodash";
import { ItemValue } from "../../components/item";
import { List } from "antd";

export function DashboardStudentView() {
  const student = useSelector((state: RootState) => state.student.current);
  const [items, setItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    const elements: any[] = [];
    elements.push({
      label: "Nom et post-nom",
      value: toUpper(student.users?.lastName),
    });
    elements.push({
      label: `Prénom`,
      value: upperFirst(student.users?.firstName),
    });
    elements.push({ label: `Date de naissance`, value: `` });
    elements.push({ label: `Lieu de naissance`, value: `` });
    elements.push({ label: `Classe`, value: `` });
    elements.push({ label: `Adresse`, value: `` });
    elements.push({ label: ``, value: `` });
    elements.push({ label: ``, value: `` });
    elements.push({ label: ``, value: `` });
    setItems(elements);
  }, [student]);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <ItemValue title={item.label} value={item.value} />
      </List.Item>
    );
  };

  return (
    <React.Fragment>
      <div className="box">
        <List
          dataSource={items}
          renderItem={renderItem}
          grid={{ gutter: 16, lg: 4, md: 3, xs: 1 }}
        />
      </div>
    </React.Fragment>
  );
}
