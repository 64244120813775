import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface periodData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: periodData = {
  current: {},
  list: [],
  all: [],
};

export const periodSlice = createSlice({
  name: "period",
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setPeriods: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllPeriods: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllPeriods, setPeriod, setPeriods } = periodSlice.actions;
export default periodSlice.reducer;
