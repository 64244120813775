import { Card, Checkbox, Layout, List, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEstablishmentCount,
  setEstablishmentFilters,
  setEstablishments,
} from "../redux/etsReducer";
import { RootState } from "../redux/store";
import establishmentService from "../services/establishmentService";
import { upper } from "../utils/text";
import { CreateEts } from "./ets/create";

export function EstablishmentScreen() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const establishments = useSelector(
    (state: RootState) => state.establishment.list
  );
  const filters = useSelector(
    (state: RootState) => state.establishment.filters
  );
  const itemLength = useSelector(
    (state: RootState) => state.establishment.itemLength
  );

  async function init() {
    const query: any[] = [];
    query.push({ item: "order[name]", value: "asc" });
    query.push({ item: "page", value: 1 });
    query.push({ item: "limit", value: 10 });
    query.push({ item: "type[]", value: "school" });
    query.push({ item: "type[]", value: "university" });
    dispatch(setEstablishmentFilters(query));
  }

  async function getEts() {
    let query = ``;
    filters.forEach((p: any) => {
      query += `${p.item}=${p.value}&`;
    });
    setLoading(true);
    await establishmentService
      .getByKey(query)
      .then((response) => {
        const data = establishmentService.getData(response);
        const count = establishmentService.getTotal(response);
        dispatch(setEstablishments(data));
        dispatch(setEstablishmentCount(count));
      })
      .catch(() => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    getEts();
  }, [filters]);

  const renderItem = (item: any) => {
    return (
      <>
        <Card className="card" style={{ margin: 5 }}>
          <Card.Meta
            title={upper(item.name)}
            description={upper(
              item.type === "school" ? "école" : "université ou institut"
            )}
          />
        </Card>
      </>
    );
  };

  function getCurrentPage() {
    const elements = filters.filter((p) => p.item === "page");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 1;
    }
  }

  function getCurrentLimit() {
    const elements = filters.filter((p) => p.item === "limit");
    if (elements.length > 0) {
      const page = elements[0].value;
      return page;
    } else {
      return 10;
    }
  }

  function getType(value: string) {
    const elements = filters.filter(
      (p) => p.item === "type[]" && p.value === value
    );
    return elements.length > 0;
  }

  function handleChangePage(page: number, pageSize: number) {
    const elements = filters.filter(
      (p) => p.item !== "page" && p.item !== "limit"
    );
    elements.push({ item: "page", value: page });
    elements.push({ item: "limit", value: pageSize });
    dispatch(setEstablishmentFilters(elements));
  }

  function handleChange(value: string) {
    const rep = getType(value);
    if (rep) {
      const elements = filters.filter(
        (p) => p.item !== "type[]" || (p.item === "type[]" && p.value !== value)
      );
      dispatch(setEstablishmentFilters(elements));
    } else {
      const elements = [...filters];
      elements.push({ item: "type[]", value });
      dispatch(setEstablishmentFilters(elements));
    }
  }

  return (
    <Layout style={{ padding: "20px 0" }}>
      <PageHeader
        title={
          <Typography.Title style={{ textTransform: "uppercase" }}>
            {"Les établissements"}
          </Typography.Title>
        }
        extra={[<CreateEts reloading={init} />]}
      ></PageHeader>
      <div>
        <Checkbox
          style={{ textTransform: "uppercase" }}
          onChange={() => handleChange("school")}
          checked={getType("school")}
        >
          {`école`}
        </Checkbox>
        <Checkbox
          style={{ textTransform: "uppercase" }}
          onChange={() => handleChange("university")}
          checked={getType("university")}
        >
          {`université ou institut`}
        </Checkbox>
      </div>
      <List
        dataSource={establishments}
        loading={loading}
        renderItem={renderItem}
        pagination={{
          current: getCurrentPage(),
          showSizeChanger: true,
          disabled: loading,
          defaultCurrent: 1,
          pageSize: getCurrentLimit(),
          total: itemLength,
          onChange: handleChangePage,
        }}
      />
    </Layout>
  );
}
