import { MoreHoriz, MoreVert } from "@material-ui/icons";
import {
  Button,
  Col,
  Descriptions,
  Layout,
  List,
  message,
  notification,
  Popover,
  Row,
  Skeleton,
  Table,
  Tabs,
  Upload,
  UploadProps,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RenderGroup } from "../components/renderGroup";
import fileService from "../services/fileService";
import lessonService from "../services/lessonService";
import subjectService from "../services/subjectService";
import subjectHelper from "../utils/helpers/subjectHelper";
import { getUid } from "../utils/text";
import { BranchSubject } from "./subject/branchs";
import { SubjectChapters } from "./subject/chapters";
import { CreateBranch } from "./subject/createBranch";
import { CreateChapiter } from "./subject/createChapiter";
import { OtherSubjects } from "./subject/others";
import { PresentationSubject } from "./subject/presentation";
import { RatingSubject } from "./subject/ratings";
import levelService from "../services/levelService";
import etsHelper from "../utils/helpers/etsHelper";

interface PopoverInterface {
  uid: string;
}

export function SubjectScreen() {
  const [subject, setSubject] = React.useState<any>({});
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [currentPeriod, setCurrentPeriod] = React.useState<any>({});
  const [branchs, setBranchs] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    init();
  }, [params.id]);

  React.useEffect(() => {
    const id = params.id;
    subjectService
      .getByKey(`level=${subject?.level?.uid}&limit=1000&order[title]=asc`)
      .then((response) => {
        let data = subjectService.getData(response);
        data = data.filter((p) => p.uid != id);
        setSubjects(data);
      })
      .catch(() => {});
  }, [params, subject]);

  async function init() {
    const id: string = params.id as string;
    setLoading(true);
    levelService
      .get(subject?.level?.uid)
      .then(async (response) => {
        const data = response.data;
        const per = await etsHelper.getCurrentPeriod(data?.groups);
        setCurrentPeriod(per);
      })
      .catch(() => {});
    await subjectService
      .get(id)
      .then((response) => {
        setSubject(response.data);
      })
      .catch(() => {});
    setLoading(false);
  }

  async function handleSaveFile(info: any, uid: string) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (!isEmpty(info.file.originFileObj)) {
      const data = new FormData();
      data.append("lesson", uid);
      data.append("file", info.file.originFileObj);
      setLoading(true);
      await fileService
        .store(data)
        .then(async () => {
          notification.info({ message: "Fichier ajouté" });
          await init();
        })
        .catch((reason) => {
          notification.error({ message: "echec" });
          console.log(reason.response);
        });
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const PopoverMenu = (props: PopoverInterface) => {
    const { uid } = props;
    return (
      <Row style={{ flexDirection: "column" }}>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            <Upload
              onChange={(info) => handleSaveFile(info, uid)}
              multiple={false}
              accept="*"
            >
              Ajouter une vidéo
            </Upload>
          </Button>
        </Col>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            Ajouter un pdf
          </Button>
        </Col>
        <Col flex={1}>
          <Button type="text" className="yc-btn-left">
            Voir la leçon
          </Button>
        </Col>
      </Row>
    );
  };

  function initChapiters() {
    subjectHelper.getChapters(params.id as string, dispatch);
  }
  async function initBranchs() {
    const elements = await subjectHelper.getBranchs(params.id as string);
    setBranchs(elements);
  }

  return (
    <Layout>
      {!loading && (
        <React.Fragment>
          <PageHeader
            onBack={() => window.history.back()}
            title={subject.title}
            extra={[]}
          >
            <Descriptions size="middle" column={2}>
              <Descriptions.Item label="Niveau">
                {`${subject?.level?.title} ${subject?.level?.reference}`}
              </Descriptions.Item>
              <Descriptions.Item label="Période de cotation">
                <span>{currentPeriod?.title}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Responsable du cours">
                <RenderGroup group={subject?.level?.groups} />
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Tabs animated centered type="card" className="yc-tab--primary">
            <Tabs.TabPane tab="Présentation" key="0">
              <PresentationSubject subject={subject} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Les côtes" key="1">
              <RatingSubject subject={subject} />
            </Tabs.TabPane>
            <Tabs.TabPane disabled tab="Les stats" key="2"></Tabs.TabPane>
            <Tabs.TabPane tab="Les autres cours" key="3">
              <OtherSubjects items={subjects} level={subject?.level} />
            </Tabs.TabPane>
          </Tabs>
        </React.Fragment>
      )}
      {loading && (
        <React.Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </React.Fragment>
      )}
    </Layout>
  );
}
