import React from "react";
import { View, Text } from "@react-pdf/renderer";

export const IdentityBulletin: React.FC<{
  province: string;
  commune: string;
  school: string;
  schoolMaricule?: string;
  fullName: string;
  bornPlace: string;
  birthDate: string;
  sex: string;
  classe: string;
  num?: number;
  studentMatricule?: string;
  edition?: string;
}> = ({
  birthDate,
  bornPlace,
  classe,
  commune,
  fullName,
  province,
  school,
  sex,
  num,
  schoolMaricule,
  studentMatricule,
  edition,
}) => {
  return (
    <React.Fragment>
      <View style={{ flexDirection: "row", borderTop: "1px solid #000" }}>
        <View style={{ borderRight: "1px solid #000", flex: 1 }}>
          <ItemView label="Province" value={province} />
          <ItemView label="Commune" value={commune} />
          <ItemView label="Ecole" value={school} />
          <ItemView label="Matricule" value={schoolMaricule ?? ""} />
        </View>
        <View style={{ flex: 1 }}>
          <ItemView label="Eleve" value={fullName} />
          <ItemView label="Né à" value={birthDate} />
          <ItemView label="Classe" value={classe} />
          <ItemView label="N°" value={`${num ?? ""}`} />
          <ItemView label="Matricule" value={studentMatricule ?? ""} />
        </View>
      </View>
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          borderTop: "1px solid #000",
        }}
      >
        <View>
          <Text style={{ padding: "5px" }}>{`Bulletin de la 5ème `}</Text>
        </View>
        <View>
          <Text style={{ padding: "5px" }}>
            {`Année scolaire ${edition ?? ""}`}
          </Text>
        </View>
      </View>
    </React.Fragment>
  );
};

const ItemView: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text style={{ padding: "5px", fontSize: 11 }}>{label}</Text>
      <Text style={{ padding: "5px", fontSize: 11 }}>{value}</Text>
    </View>
  );
};
