import { Col, Layout, Row, Skeleton } from "antd";
import { groupBy } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setUserGroups,
  setUserLessons,
  setUserTeachers,
} from "../redux/userReducer";
import groupService from "../services/groupService";
import lessonService from "../services/lessonService";
import teacherService from "../services/teacherService";
import dashboardHelper from "../utils/helpers/dashboardHelper";
import { GroupDashboard } from "./dashboard/group";
import { LastStudentSaving } from "./dashboard/lastStudentSaving";
import { LastSubscription } from "./dashboard/lastSubscription";
import { LastSubscriptionAmount } from "./dashboard/lastSubscriptionAmount";
import { LessonsDashboard } from "./dashboard/lessons";
import { StatContent } from "./dashboard/statContent";
import { StatStructure } from "./dashboard/statStructure";
import { TeacherDashboard } from "./dashboard/teacher";

export function DashboardScreen() {
  const dispatch = useDispatch();
  const groups = useSelector((state: RootState) => state.user.groups);
  const ets = useSelector((state: RootState) => state.establishment.profil);
  const lessons = useSelector((state: RootState) => state.user.lessons);
  const teachers = useSelector((state: RootState) => state.user.teachers);
  const [cycles, setCycles] = React.useState<any[]>([]);
  const [faculties, setFaculties] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  async function getTeachers() {
    await teacherService
      .getByKey(`limit=5`)
      .then((response) => {
        const data = teacherService.getData(response);
        dispatch(setUserTeachers(data));
      })
      .catch(() => {});
  }

  async function getGroups() {
    await groupService
      .getByKey(`type[]=cycle&type[]=faculty&establishment=${ets?.uid}`)
      .then((response) => {
        const data = groupService.getData(response);
        dispatch(setUserGroups(data));
      })
      .catch(() => {});
  }

  async function getLessons() {
    await lessonService
      .getByKey(``)
      .then((response) => {
        const data = lessonService.getData(response);
        dispatch(setUserLessons(data));
      })
      .catch(() => {});
  }

  function enableLoading(...items: any) {
    let i = 0;
    items.forEach((p: any[]) => {
      if (p.length === 0) {
        i++;
      }
    });
    return i > 1;
  }

  async function init() {
    if (enableLoading(lessons, groups, teachers)) {
      setLoading(true);
    }
    await dashboardHelper.getStatistic(dispatch);
    if (teachers.length === 0) {
      await getTeachers();
    }
    if (lessons.length === 0) {
      await getLessons();
    }
    if (groups.length === 0) {
      getGroups();
    }
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  const LoadingView = () => {
    return (
      <React.Fragment>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    const items = groupBy(groups, (p) => p.type);
    setCycles(items.cycle ?? []);
    setFaculties(items.faculty ?? []);
  }, [groups]);

  return (
    <React.Fragment>
      {loading && <LoadingView />}
      {!loading && (
        <Layout style={{ minHeight: "100vh" }} className="box">
          <StatStructure />
          <Row gutter={8} align="stretch">
            <Col lg={12}>
              <LastStudentSaving />
            </Col>
            <Col lg={12}>{/**liste des derniers étudiants ajoutés */}</Col>
            <Col lg={16}>
              <LastSubscription />
            </Col>
            <Col lg={8}>
              <LastSubscriptionAmount />
            </Col>
          </Row>
          <StatContent />
        </Layout>
      )}
    </React.Fragment>
  );
}
